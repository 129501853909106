export const EXPIRED_SESSION_EVENT = 'expired_session';
export const GET_LOGGED_USER = 'get_logged_user';

const eventBusService = {
    on(event, callback) {
        document.addEventListener(event, (e) => callback(e.detail));
    },
    dispatch(event, data) {
        document.dispatchEvent(new CustomEvent(event, { detail: data }));
    },
    remove(event, callback) {
        document.removeEventListener(event, callback);
    },
};

export default eventBusService;