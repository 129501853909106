
import { DataService } from "../config/dataService";
import { defaultPageSize } from "../utility/constants";

class UsuarioService extends DataService {

    getUsuario = (id) => this.get(`/users/${id}`)

    getList = (page = 0, pageSize = defaultPageSize, filters = '') => this.get(`/users/?limit=${pageSize}&offset=${page * pageSize}${filters}`);

    getListOnlyFilter = (filter = "ordering=-id") => this.get(`/users?${filter}`);

    create = payload => this.post('/users/', payload);

    createUserParams = payload => this.post("/users/parametros", payload);

    updateUserParams = (id, payload) => this.patch(`/users/parametros/${id}`, payload);

    update = (id, payload) => this.put(`/users/${id}`, payload);

    partialUpdate = (id, payload) => this.patch(`/users/${id}`, payload);

    getUserHistory = (filters) => this.get(`users/logs/?${filters}`);

    getUserLogs = (id) => this.get(`users/logs/${id}`);

    remove = id => this.delete(`/users/${id}`);
}

export default UsuarioService;