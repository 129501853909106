export const mocks = [
    {
            "url": "/clientes?ordering=number",
            "extra": ["id", "search", "tipo_cliente", "cuit", "razon_social", "ordering"],
            "id": 0,
            "cat_iva_id": 0,
            "cat_iva": {
              "id": 0,
              "nombre": "Sin Definir"
            },
            "provincia_id": 0,
            "provincia": {
              "id": 0,
              "provincia_localidades": [
                {
                  "id": 0,
                  "provincia": 0,
                  "nombre": "Sin Definir"
                }
              ],
              "nombre": "Sin Definir"
            },
            "localidad_id": 0,
            "localidad": {
              "id": 0,
              "provincia": 0,
              "nombre": "Sin Definir"
            },
            "cnv_categ_id": 0,
            "cnv_categ": {
              "id": 0,
              "nombre": "Sin Definir"
            },
            "tipo_cliente_id": 0,
            "tipo_cliente": {
              "id": 0,
              "nombre_tipo": "Sin Definir"
            },
            "razon_social": "Sin Definir",
            "cuit": "-",
            "domicilio": "Sin Definir",
            "telefono": "Sin Definir",
            "email_dif_pub": "Sin Definir",
            "email_institucional": "Sin Definir",
            "sitio_web": "Sin Definir",
            "fec_creacion": "-",
            "activo": null,
            "cod_caja_cpd": "-",
            "cod_op": "-",
            "membresia": null,
},
{
  "url": "/inhibiciones",
  "id": 1,
  "fecha": "",
  "hora": "",
  "inhibe": false,
  "mensaje": "Sin Definir",
  "tiempo": 1
},
{
    "url": "/clientes/id",
    "id": 0,
    "cat_iva": {
      "id": 0,
      "nombre": "Sin Definir"
    },
    "provincia": {
      "id": 0,
      "provincia_localidades": [
        {
          "id": 0,
          "provincia": 0,
          "nombre": "Sin Definir"
        }
      ],
      "nombre": "Sin Definir"
    },
    "localidad_id": 0,
    "localidad": {
      "id": 0,
      "provincia": 0,
      "nombre": "Sin Definir"
    },
    "cnv_categ_id": 0,
    "cnv_categ": {
      "id": 0,
      "nombre": "Sin Definir"
    },
    "tipo_cliente": {
      "id": 0,
      "nombre_tipo": "Sin Definir"
    },
    "razon_social": "Sin Definir",
    "cuit": "-",
    "domicilio": "Sin Definir",
    "telefono": "Sin Definir",
    "email_dif_pub": "Sin Definir",
    "email_institucional": "Sin Definir",
    "sitio_web": "Sin Definir",
    "fec_creacion": "-",
    "activo": null,
    "cod_caja_cpd": "-",
    "cod_op": "-",
    "membresia": null,
},
{
    url: "/clientes/tipos",
    id: "",
    nombre_tipo: "Sin Definir"
},
{
    url: "/categorias_cnv",
    id: "",
    nombre: "Sin Definir"
},
{
    url: "/segmentos/denominacion",
    "id": "",
    "nombre": "Sin Definir",
    "parent": ""
},
{
    url: "/categorias_iva",
    id: "",
    nombre: "Sin Definir"
},
{
    url: "/bancos",
    id: "",
    codigo_banco: "Sin Definir",
    nombre_banco: "Sin Definir",
    abreviatura_nombre_banco: "Sin Definir",
    is_active: null
},
{
    url: "/instrumentos",
    id: "",
    tipo: "Sin Definir",
    descripcion: "Sin Definir",
    derecho_de_mercado_comprador: "-",
    derecho_de_mercado_vendedor: "-",
    iva_de_mercado_comprador: "-",
    iva_de_mercado_vendedor: "-",
    base_de_prorrateo: "-",
    monto_minimo: "-",
    monto_maximo: null,
    is_active: null
},
{
    url: "/parameters",
    id: "",
    hora_neg_desde: "Sin Definir",
    hora_neg_hasta: "Sin Definir",
    dias_liquidacion: "Sin Definir",
    is_active: null,
    vencimiento_clave: "-",
    email_cv_remesa: "Sin Definir"
},
{
    url: "/cheques/parametros",
    id: "",
    vencimiento_minimo: "-",
    dias_autorizacion: "-",
    vencimiento_desde_emision: "-",
    ano_duracion_cheque: "-",
    cantidad_maxima_endosos: "-"
},
{
    url: "/custodios",
    id: "",
    denominacion: "Sin Definir",
    tipo: "Sin Definir"
},
{
    url: "/feriados",
    fecha_feriado: "-",
    id: "",
    motivo: "Sin Definir",
    tipo: "Sin Definir"
},
{
    url: "/users/type",
    id: "",
    name: "Sin Definir",
    id_perfil: null
},
{
    url: "/segmentos/tipo",
    id: "",
    nombre: "Sin Definir"
},
{
    url: "/moneda",
    id: "",
    denominacion: "Sin Definir",
    signo: "-"
},
{
    url: "/province",
    id: "",
    provincia_localidades: [
        {
            id: "",
            nombre: "Sin Definir"
        }
    ],
    nombre: "Sin Definir"
},
{
    url: "/province/city",
    id: "",
    nombre: "Sin Definir"
},
{
    url: "/plazos",
    id: "",
    nombre: "Sin Definir",
    hora_inicio: "-",
    hora_final: "-"
},
{
    url: "/moneda/cotizaciones",
    id: "",
    moneda: {
        id: "",
        denominacion: "Sin Definir",
        signo: "-"
        },
    fecha: null,
    precio_comprador: "-",
    precio_vendedor: "-"
},
{
    url: "/remesa/number",
        id: "",
        nro_cheques: [
            {
                id: "",
                cuenta: "-",
                sucursal: "-",
                plaza: "-",
                cuit_librador: "-",
                monto: "-",
                endosos: "-",
                comitente_presentante: {
                    cuit: "-",
                    nro_comitente: "-",
                    exceptuado: null,
                    id: "",
                    nombre: "Sin Definir",
                    persona_juridica: null,
                    codigo_operador: {
                        id: "",
                        cat_iva: {
                            id: "",
                            nombre: "Sin Definir"
                        },
                        provincia: {
                            id: "",
                            provincia_localidades: [
                                {
                                    id: "",
                                    nombre: "Sin Definir"
                                }
                            ],
                            nombre: "Sin Definir"
                        },
                        localidad: {
                            id: "",
                            nombre: "Sin Definir"
                        },
                        cnv_categ: {
                            id: "",
                            nombre: "Sin Definir"
                        },
                        tipo_cliente: {
                            id: "",
                            nombre_tipo: "Sin Definir"
                        },
                        razon_social: "Sin Definir",
                        cuit: "-",
                        domicilio: "Sin Definir",
                        telefono: "Sin Definir",
                        email_dif_pub: "Sin Definir",
                        email_institucional: "Sin Definir",
                        sitio_web: "Sin Definir",
                        fec_creacion: "-",
                        fec_actualiz: "-",
                        activo: null,
                        cod_caja_cpd: "-",
                        cod_caja_val: "-",
                        membresia: null,
                        codigo_op_old: "-"
                    },
                    is_active: null
                },
                comitente_tenedor: {
                    cuit: "-",
                    nro_comitente: "-",
                    exceptuado: null,
                    id: "",
                    nombre: "Sin Definir",
                    persona_juridica: null,
                    codigo_operador: {
                        id: "",
                        cat_iva: {
                            id: "",
                            nombre: "Sin Definir"
                        },
                        provincia: {
                            id: "",
                            provincia_localidades: [
                                {
                                    id: "",
                                    nombre: "Sin Definir"
                                }
                            ],
                            nombre: "Sin Definir"
                        },
                        localidad: {
                            id: "",
                            nombre: "Sin Definir"
                        },
                        cnv_categ: {
                            id: "",
                            nombre: "Sin Definir"
                        },
                        tipo_cliente: {
                            id: "",
                            nombre_tipo: "Sin Definir"
                        },
                        razon_social: "Sin Definir",
                        cuit: "-",
                        domicilio: "Sin Definir",
                        telefono: "Sin Definir",
                        email_dif_pub: "Sin Definir",
                        email_institucional: "Sin Definir",
                        sitio_web: "Sin Definir",
                        fec_creacion: "-",
                        fec_actualiz: "-",
                        activo: null,
                        cod_caja_cpd: "-",
                        cod_caja_val: "-",
                        membresia: null,
                        codigo_op_old: "-"
                    },
                    is_active: null
                },
                banco: {
                    id: "",
                    codigo_banco: "Sin Definir",
                    nombre_banco: "Sin Definir",
                    abreviatura_nombre_banco: "Sin Definir",
                    is_active: null
                },
                segmento: {
                    id: "",
                    tipo: "Sin Definir",
                    denominacion: "Sin Definir"
                },
                custodio: "Sin Definir",
                usuario: {
                    id: "",
                    cliente: {
                        id: "",
                        cat_iva: {
                            id: "",
                            nombre: "Sin Definir"
                        },
                        provincia: {
                            id: "",
                            provincia_localidades: [
                                {
                                    id: "",
                                    nombre: "Sin Definir"
                                }
                            ],
                            nombre: "Sin Definir"
                        },
                        localidad: {
                            id: "",
                            nombre: "Sin Definir"
                        },
                        cnv_categ: {
                            id: "",
                            nombre: "Sin Definir"
                        },
                        tipo_cliente: {
                            id: "",
                            nombre_tipo: "Sin Definir"
                        },
                        razon_social: "Sin Definir",
                        cuit: "-",
                        domicilio: "Sin Definir",
                        telefono: "Sin Definir",
                        email_dif_pub: "Sin Definir",
                        email_institucional: "Sin Definir",
                        sitio_web: "Sin Definir",
                        fec_creacion: "-",
                        fec_actualiz: "-",
                        activo: null,
                        cod_caja_cpd: "-",
                        cod_caja_val: "-",
                        membresia: null,
                        codigo_op_old: "-"
                    },
                    user_type: {
                        id: "",
                        name: "Sin Definir"
                    },
                    user_params: null,
                    last_login: "-",
                    first_name: "Sin Definir",
                    last_name: "Sin Definir",
                    is_staff: null,
                    is_active: null,
                    date_joined: "-",
                    email: "Sin Definir",
                    date_modified: null,
                    photo: "https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png",
                    admin: null,
                    admin_cupos: null,
                    admin_dma: null,
                    cuit: "-",
                    caduca: "-"
                },
                instrumento: {
                    id: "",
                    tipo: "Sin Definir",
                    descripcion: "Sin Definir",
                    derecho_de_mercado_comprador: "-",
                    derecho_de_mercado_vendedor: "-",
                    iva_de_mercado_comprador: "-",
                    iva_de_mercado_vendedor: "-",
                    base_de_prorrateo: "-",
                    monto_minimo: "-",
                    monto_maximo: null,
                    is_active: null
                },
                moneda: {
                    id: "",
                    denominacion: "Sin Definir",
                    signo: "-"
                },
                lote: null,
                remesa: "-",
                estado: {
                    id: "",
                    estado_nombre: "Sin Definir"
                },
                certificado_pyme: null,
                fecha_emision: "-",
                fecha_de_pago: "-",
                fecha_de_cobro: "-",
                nro_cheque: "-",
                clausula_no_a_la_orden: null,
                endosado_sin_recurso: null,
                primera_negociacion: null,
                expone_librador: null,
                pyme: null,
                echeq_id: null,
                codigo: null,
                svs: null
            }
        ],
        codigo_operador: {
            id: "",
            cat_iva: {
                id: "",
                nombre: "Sin Definir"
            },
            provincia: {
                id: "",
                provincia_localidades: [
                    {
                        id: "",
                        nombre: "Sin Definir"
                    }
                ],
                nombre: "Sin Definir"
            },
            localidad: {
                id: "",
                nombre: "Sin Definir"
            },
            cnv_categ: {
                id: "",
                nombre: "Sin Definir"
            },
            tipo_cliente: {
                id: "",
                nombre_tipo: "Sin Definir"
            },
            razon_social: "Sin Definir",
            cuit: "-",
            domicilio: "Sin Definir",
            telefono: "Sin Definir",
            email_dif_pub: "Sin Definir",
            email_institucional: "Sin Definir",
            sitio_web: "Sin Definir",
            fec_creacion: "-",
            fec_actualiz: "-",
            activo: null,
            cod_caja_cpd: "-",
            cod_caja_val: "-",
            membresia: null,
            codigo_op_old: "-"
        },
        usuario_revisor: null,
        fecha_hora: "-",
        usuario_creador: "Sin Definir",
        estado: null
},
{
    url: "/remesa/?ordering=number",
        id: "",
        nro_cheques: [
            {
                id: "",
                cuenta: "-",
                sucursal: "-",
                plaza: "-",
                cuit_librador: "-",
                monto: "-",
                endosos: "-",
                comitente_presentante: {
                    cuit: "-",
                    nro_comitente: "-",
                    exceptuado: null,
                    id: "",
                    nombre: "Sin Definir",
                    persona_juridica: null,
                    codigo_operador: {
                        id: "",
                        cat_iva: {
                            id: "",
                            nombre: "Sin Definir"
                        },
                        provincia: {
                            id: "",
                            provincia_localidades: [
                                {
                                    id: "",
                                    nombre: "Sin Definir"
                                }
                            ],
                            nombre: "Sin Definir"
                        },
                        localidad: {
                            id: "",
                            nombre: "Sin Definir"
                        },
                        cnv_categ: {
                            id: "",
                            nombre: "Sin Definir"
                        },
                        tipo_cliente: {
                            id: "",
                            nombre_tipo: "Sin Definir"
                        },
                        razon_social: "Sin Definir",
                        cuit: "-",
                        domicilio: "Sin Definir",
                        telefono: "Sin Definir",
                        email_dif_pub: "Sin Definir",
                        email_institucional: "Sin Definir",
                        sitio_web: "Sin Definir",
                        fec_creacion: "-",
                        fec_actualiz: "-",
                        activo: null,
                        cod_caja_cpd: "-",
                        cod_caja_val: "-",
                        membresia: null,
                        codigo_op_old: "-"
                    },
                    is_active: null
                },
                comitente_tenedor: {
                    cuit: "-",
                    nro_comitente: "-",
                    exceptuado: null,
                    id: "",
                    nombre: "Sin Definir",
                    persona_juridica: null,
                    codigo_operador: {
                        id: "",
                        cat_iva: {
                            id: "",
                            nombre: "Sin Definir"
                        },
                        provincia: {
                            id: "",
                            provincia_localidades: [
                                {
                                    id: "",
                                    nombre: "Sin Definir"
                                }
                            ],
                            nombre: "Sin Definir"
                        },
                        localidad: {
                            id: "",
                            nombre: "Sin Definir"
                        },
                        cnv_categ: {
                            id: "",
                            nombre: "Sin Definir"
                        },
                        tipo_cliente: {
                            id: "",
                            nombre_tipo: "Sin Definir"
                        },
                        razon_social: "Sin Definir",
                        cuit: "-",
                        domicilio: "Sin Definir",
                        telefono: "Sin Definir",
                        email_dif_pub: "Sin Definir",
                        email_institucional: "Sin Definir",
                        sitio_web: "Sin Definir",
                        fec_creacion: "-",
                        fec_actualiz: "-",
                        activo: null,
                        cod_caja_cpd: "-",
                        cod_caja_val: "-",
                        membresia: null,
                        codigo_op_old: "-"
                    },
                    is_active: null
                },
                banco: {
                    id: "",
                    codigo_banco: "Sin Definir",
                    nombre_banco: "Sin Definir",
                    abreviatura_nombre_banco: "Sin Definir",
                    is_active: null
                },
                segmento: {
                    id: "",
                    tipo: "Sin Definir",
                    denominacion: "Sin Definir"
                },
                custodio: "Sin Definir",
                usuario: {
                    id: "",
                    cliente: {
                        id: "",
                        cat_iva: {
                            id: "",
                            nombre: "Sin Definir"
                        },
                        provincia: {
                            id: "",
                            provincia_localidades: [
                                {
                                    id: "",
                                    nombre: "Sin Definir"
                                }
                            ],
                            nombre: "Sin Definir"
                        },
                        localidad: {
                            id: "",
                            nombre: "Sin Definir"
                        },
                        cnv_categ: {
                            id: "",
                            nombre: "Sin Definir"
                        },
                        tipo_cliente: {
                            id: "",
                            nombre_tipo: "Sin Definir"
                        },
                        razon_social: "Sin Definir",
                        cuit: "-",
                        domicilio: "Sin Definir",
                        telefono: "Sin Definir",
                        email_dif_pub: "Sin Definir",
                        email_institucional: "Sin Definir",
                        sitio_web: "Sin Definir",
                        fec_creacion: "-",
                        fec_actualiz: "-",
                        activo: null,
                        cod_caja_cpd: "-",
                        cod_caja_val: "-",
                        membresia: null,
                        codigo_op_old: "-"
                    },
                    user_type: {
                        id: "",
                        name: "Sin Definir"
                    },
                    user_params: null,
                    last_login: "-",
                    first_name: "Sin Definir",
                    last_name: "Sin Definir",
                    is_staff: null,
                    is_active: null,
                    date_joined: "-",
                    email: "Sin Definir",
                    date_modified: null,
                    photo: "https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png",
                    admin: null,
                    admin_cupos: null,
                    admin_dma: null,
                    cuit: "-",
                    caduca: "-"
                },
                instrumento: {
                    id: "",
                    tipo: "Sin Definir",
                    descripcion: "Sin Definir",
                    derecho_de_mercado_comprador: "-",
                    derecho_de_mercado_vendedor: "-",
                    iva_de_mercado_comprador: "-",
                    iva_de_mercado_vendedor: "-",
                    base_de_prorrateo: "-",
                    monto_minimo: "-",
                    monto_maximo: null,
                    is_active: null
                },
                moneda: {
                    id: "",
                    denominacion: "Sin Definir",
                    signo: "-"
                },
                lote: null,
                remesa: "-",
                estado: {
                    id: "",
                    estado_nombre: "Sin Definir"
                },
                certificado_pyme: null,
                fecha_emision: "-",
                fecha_de_pago: "-",
                fecha_de_cobro: "-",
                nro_cheque: "-",
                clausula_no_a_la_orden: null,
                endosado_sin_recurso: null,
                primera_negociacion: null,
                expone_librador: null,
                pyme: null,
                echeq_id: null,
                codigo: null,
                svs: null
            }
        ],
        codigo_operador: {
            id: "",
            cat_iva: {
                id: "",
                nombre: "Sin Definir"
            },
            provincia: {
                id: "",
                provincia_localidades: [
                    {
                        id: "",
                        nombre: "Sin Definir"
                    }
                ],
                nombre: "Sin Definir"
            },
            localidad: {
                id: "",
                nombre: "Sin Definir"
            },
            cnv_categ: {
                id: "",
                nombre: "Sin Definir"
            },
            tipo_cliente: {
                id: "",
                nombre_tipo: "Sin Definir"
            },
            razon_social: "Sin Definir",
            cuit: "-",
            domicilio: "Sin Definir",
            telefono: "Sin Definir",
            email_dif_pub: "Sin Definir",
            email_institucional: "Sin Definir",
            sitio_web: "Sin Definir",
            fec_creacion: "-",
            fec_actualiz: "-",
            activo: null,
            cod_caja_cpd: "-",
            cod_caja_val: "-",
            membresia: null,
            codigo_op_old: "-"
        },
        usuario_revisor: null,
        fecha_hora: "-",
        usuario_creador: "Sin Definir",
        estado: null
},
{
    url: "/garantias_cpd",
    extra: ["codigo_operador__id", "search", "comitente__nro_comitente", "ordering", "comitente__nombre", "estado", "cotizacion__cod_esp", "cantidad", "movimiento"],
    id: "",
    comitente: {
        cuit: "-",
        nro_comitente: "-",
        exceptuado: null,
        id: "",
        nombre: "Sin Definir",
        persona_juridica: null,
        codigo_operador: {
            id: "",
            cat_iva: {
                id: "",
                nombre: "Sin Definir"
                },
            provincia: {
                id: "",
                provincia_localidades: [
                        {
                        id: "",
                        nombre: "Sin Definir"
                        }
                    ],
                nombre: "Sin Definir"
                },
            localidad: {
                id: "",
                nombre: "Sin Definir"
                },
            cnv_categ: {
                id: "",
                nombre: "Sin Definir"
                },
            tipo_cliente: {
                id: "",
                nombre_tipo: "Sin Definir"
                },
            razon_social: "Sin Definir",
            cuit: "-",
            domicilio: "Sin Definir",
            telefono: "Sin Definir",
            email_dif_pub: "Sin Definir",
            email_institucional: "Sin Definir",
            sitio_web: "Sin Definir",
            fec_creacion: "-",
            fec_actualiz: "-",
            activo: null,
            cod_caja_cpd: "-",
            cod_caja_val: "-",
            membresia: null,
            codigo_op_old: "-"
            },
        is_active: null
        },
        cotizacion: {
            id: "",
            fecha: "-",
            nro_esp: "-",
            cod_esp: "-",
            nom_tipo: "-",
            cod_plazo: "-",
            precio_por: "-",
            cierre_anterior: "-",
            aforo: "-",
            precio_aforado: "-",
            fec_creacion: "-",
            fec_actualiz: null,
            is_active: null
        },
        fecha: "-",
        cantidad: "-",
        movimiento: "Sin Definir",
        estado: "Sin Definir",
        codigo_operador: "-"
},
{
    url: "/cheques/sdib?ordering=number",
    "id": "",
    "fecha_proceso": "-",
    "estado": "Sin Definir",
    "fecha_fin_proceso": "-",
    "cantidad_registros_procesados": "Sin Definir",
    "errores": null,
    "usuario": "Sin Definir"
}, 
{
    url: "/cheques/gic?ordering=number",
    "id": "",
    "fecha_proceso": "-",
    "estado": "Sin Definir",
    "fecha_fin_proceso": "-",
    "cantidad_registros_procesados": "Sin Definir",
    "errores": null,
    "usuario": "Sin Definir"
},
{
    url: "/users?ordering=number",
    extra: ["user_type__name", "id", "search", "ordering"],
    "id": "",
    "cliente": {
    "id": "",
    "cat_iva_id": "",
    "cat_iva": {
    "id": "",
    "nombre": "Sin Definir"
    },
    "provincia_id": "",
    "provincia": {
    "id": "",
    "provincia_localidades": [
    {
    "id": "",
    "provincia": "Sin Definir",
    "nombre": "Sin Definir"
    }
    ],
    "nombre": "Sin Definir"
    },
    "localidad_id": "",
    "localidad": {
    "id": "",
    "provincia": "",
    "nombre": "Sin Definir"
    },
    "cnv_categ_id": "",
    "cnv_categ": {
    "id": "",
    "nombre": "Sin Definir"
    },
    "tipo_cliente_id": "",
    "tipo_cliente": {
    "id": "",
    "nombre_tipo": "Sin Definir"
    },
    "razon_social": "Sin Definir",
    "cuit": "-",
    "domicilio": "Sin Definir",
    "telefono": "Sin Definir",
    "email_dif_pub": "Sin Definir",
    "email_institucional": "Sin Definir",
    "sitio_web": "Sin Definir",
    "fec_creacion": "-",
    "fec_actualiz": "-",
    "activo": null,
    "cod_caja_cpd": "-",
    "cod_caja_val": "-",
    "membresia": null,
    "codigo_op_old": "-"
    },
    "cliente_id": "",
    "user_type": {
    "id": "",
    "name": "Sin Definir",
    "id_perfil": null
    },
    "user_type_id": "",
    "user_params_id": {
    "property1": "Sin Definir",
    "property2": "Sin Definir"
    },
    "user_params": null,
    "last_login": "-",
    "first_name": "Sin Definir",
    "last_name": "Sin Definir",
    "is_staff": null,
    "is_active": null,
    "date_joined": "-",
    "email": "Sin Definir",
    "date_modified": "-",
    "photo": "https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png",
    "admin": null,
    "admin_cupos": null,
    "admin_dma": null,
    "cuit": "-",
    "username": "-"
},
{
    url: "/pymes/condiciones?ordering=number",
    "id": "",
    "nombre": "Sin Definir"
},
{
    url: "/pymes?ordering=-id",
    "id": "",
    "razon_social": "Sin Definir",
    "cuit": "-",
    "fecha_vencimiento": "-",
    "condicion": {
        "id": "",
        "nombre": "Sin Definir"
    },
    "documentacion": null,
    "activo": null
},
{
    url: "/certificantes?ordering=number",
    "id": "",
    "cuit": "-",
    "razon_social": "Sin Definir",
    "activo": null
},
{
    url: "/users/cartera_limitada?ordering=number",
    "id": 2,
    "user": {
        "id": 9,
        "cliente": {
            "id": 0,
            "cat_iva_id": 0,
            "cat_iva": {
              "id": 0,
              "nombre": "Sin Definir"
            },
            "provincia_id": 0,
            "provincia": {
              "id": 0,
              "provincia_localidades": [
                {
                  "id": 0,
                  "provincia": 0,
                  "nombre": "Sin Definir"
                }
              ],
              "nombre": "Sin Definir"
            },
            "localidad_id": 0,
            "localidad": {
              "id": 0,
              "provincia": 0,
              "nombre": "Sin Definir"
            },
            "cnv_categ_id": 0,
            "cnv_categ": {
              "id": 0,
              "nombre": "Sin Definir"
            },
            "tipo_cliente_id": 0,
            "tipo_cliente": {
              "id": 0,
              "nombre_tipo": "Sin Definir"
            },
            "razon_social": "Sin Definir",
            "cuit": "-",
            "domicilio": "Sin Definir",
            "telefono": "Sin Definir",
            "email_dif_pub": "Sin Definir",
            "email_institucional": "Sin Definir",
            "sitio_web": "Sin Definir",
            "fec_creacion": "-",
            "fec_actualiz": "-",
            "activo": null,
            "cod_caja_cpd": "-",
            "cod_caja_val": "-",
            "membresia": null,
            "codigo_op_old": "-"
},
        "user_type": {
            "id": "",
            "name": "Sin Definir",
            "id_perfil": null
        },
        "user_params": null,
        "last_login": "-",
        "first_name": "Sin Definir",
        "last_name": "Sin Definir",
        "is_staff": null,
        "is_active": null,
        "date_joined": "-",
        "email": "Sin Definir",
        "date_modified": "-",
        "photo": "https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png",
        "admin": null,
        "admin_cupos": null,
        "admin_dma": null,
        "cuit": "-",
        "username": "-"
    },
    "comitente": {
        "cuit": "-",
        "nro_comitente": "-",
        "exceptuado": null,
        "id": "",
        "nombre": "Sin Definir",
        "persona_juridica": null,
        "codigo_operador": {
            "id": "",
            "cat_iva": {
                "id": "",
                "nombre": "Sin Definir"
            },
            "provincia": {
                "id": "",
                "provincia_localidades": [
                    {
                        "id": "",
                        "nombre": "Sin Definir"
                    },
                ],
                "nombre": "Sin Definir"
            },
            "localidad": {
                "id": "",
                "nombre": "Sin Definir"
            },
            "cnv_categ": {
                "id": "",
                "nombre": "Sin Definir"
            },
            "tipo_cliente": {
                "id": "",
                "nombre_tipo": "Sin Definir"
            },
            "razon_social": "Sin Definir",
            "cuit": "-",
            "domicilio": "Sin Definir",
            "telefono": "Sin Definir",
            "email_dif_pub": "Sin Definir",
            "email_institucional": "Sin Definir",
            "sitio_web": "Sin Definir",
            "fec_creacion": "-",
            "fec_actualiz": null,
            "activo": null,
            "cod_caja_cpd": "",
            "cod_caja_val": "",
            "membresia": null,
            "codigo_op_old": null
        },
        "is_active": null
    }
},
{
    url: "/comitentes?ordering=number",
    extra: ["search", "nro_comitente", "nombre", "cuit", "exceptuado", ""],
    "cuit": "-",
    "nro_comitente": "-",
    "exceptuado": null,
    "id": "",
    "nombre": "Sin Definir",
    "persona_juridica": null,
    "codigo_operador": {
        "id": "",
        "cat_iva": {
            "id": "",
            "nombre": "Sin Definir"
        },
        "provincia": {
            "id": "",
            "provincia_localidades": [
                {
                    "id": "",
                    "nombre": "Sin Definir"
                },
            ],
            "nombre": "Sin Definir"
        },
        "localidad": {
            "id": "",
            "nombre": "Sin Definir"
        },
        "cnv_categ": {
            "id": "",
            "nombre": "Sin Definir"
        },
        "tipo_cliente": {
            "id": "",
            "nombre_tipo": "Sin Definir"
        },
        "razon_social": "Sin Definir",
        "cuit": "-",
        "domicilio": "Sin Definir",
        "telefono": "Sin Definir",
        "email_dif_pub": "Sin Definir",
        "email_institucional": "Sin Definir",
        "sitio_web": "Sin Definir",
        "fec_creacion": "-",
        "fec_actualiz": null,
        "activo": null,
        "cod_caja_cpd": "",
        "cod_caja_val": "",
        "membresia": null,
        "codigo_op_old": null
    },
    "is_active": null
},
{
    url: "/garantias_cpd?ordering=number",
    extra: ["search", "comitente__nro_comitente", "ordering", "estado", "movimiento"],
        "id": "",
        "comitente_id": "",
        "comitente": {
          "cuit": "-",
          "nro_comitente": "-",
          "exceptuado": null,
          "id": "",
          "nombre": "Sin Definir",
          "persona_juridica": null,
          "codigo_operador": {
            "id": "",
            "cat_iva_id": "",
            "cat_iva": {
              "id": "",
              "nombre": "Sin Definir"
            },
            "provincia_id": "",
            "provincia": {
              "id": "",
              "provincia_localidades": [
                {
                  "id": "",
                  "provincia": "Sin Definir",
                  "nombre": "Sin Definir"
                }
              ],
              "nombre": "Sin Definir"
            },
            "localidad_id": "",
            "localidad": {
              "id": "",
              "provincia": "Sin Definir",
              "nombre": "Sin Definir"
            },
            "cnv_categ_id": "",
            "cnv_categ": {
              "id": "",
              "nombre": "Sin Definir"
            },
            "tipo_cliente_id": "",
            "tipo_cliente": {
              "id": "",
              "nombre_tipo": "Sin Definir"
            },
            "razon_social": "Sin Definir",
            "cuit": "-",
            "domicilio": "Sin Definir",
            "telefono": "Sin Definir",
            "email_dif_pub": "Sin Definir",
            "email_institucional": "Sin Definir",
            "sitio_web": "Sin Definir",
            "fec_creacion": "-",
            "fec_actualiz": "-",
            "activo": null,
            "cod_caja_cpd": "",
            "cod_caja_val": "",
            "membresia": null,
            "codigo_op_old": ""
          },
          "is_active": null
        },
        "cotizacion_id": "",
        "cotizacion": {
          "id": "",
          "fecha": "-",
          "nro_esp": "-",
          "cod_esp": "-",
          "nom_tipo": "-",
          "cod_plazo": "-",
          "precio_por": "-",
          "cierre_anterior": "-",
          "aforo": "-",
          "precio_aforado": "-",
          "fec_creacion": "-",
          "fec_actualiz": "-",
          "is_active": null
        },
        "fecha": "-",
        "cantidad": "-",
        "movimiento": "Sin Definir",
        "estado": "Sin Definir",
        "codigo_operador": ""
},
{
    url: "/garantias_cpd?ordering=number",
        "id": "",
        "comitente_id": "",
        "comitente": {
          "cuit": "-",
          "nro_comitente": "-",
          "exceptuado": null,
          "id": "",
          "nombre": "Sin Definir",
          "persona_juridica": null,
          "codigo_operador": {
            "id": "",
            "cat_iva_id": "",
            "cat_iva": {
              "id": "",
              "nombre": "Sin Definir"
            },
            "provincia_id": "",
            "provincia": {
              "id": "",
              "provincia_localidades": [
                {
                  "id": "",
                  "provincia": "Sin Definir",
                  "nombre": "Sin Definir"
                }
              ],
              "nombre": "Sin Definir"
            },
            "localidad_id": "",
            "localidad": {
              "id": "",
              "provincia": "Sin Definir",
              "nombre": "Sin Definir"
            },
            "cnv_categ_id": "",
            "cnv_categ": {
              "id": "",
              "nombre": "Sin Definir"
            },
            "tipo_cliente_id": "",
            "tipo_cliente": {
              "id": "",
              "nombre_tipo": "Sin Definir"
            },
            "razon_social": "Sin Definir",
            "cuit": "-",
            "domicilio": "Sin Definir",
            "telefono": "Sin Definir",
            "email_dif_pub": "Sin Definir",
            "email_institucional": "Sin Definir",
            "sitio_web": "Sin Definir",
            "fec_creacion": "-",
            "fec_actualiz": "-",
            "activo": null,
            "cod_caja_cpd": "",
            "cod_caja_val": "",
            "membresia": null,
            "codigo_op_old": ""
          },
          "is_active": null
        },
        "cotizacion_id": "",
        "cotizacion": {
          "id": "",
          "fecha": "-",
          "nro_esp": "-",
          "cod_esp": "-",
          "nom_tipo": "-",
          "cod_plazo": "-",
          "precio_por": "-",
          "cierre_anterior": "-",
          "aforo": "-",
          "precio_aforado": "-",
          "fec_creacion": "-",
          "fec_actualiz": "-",
          "is_active": null
        },
        "fecha": "-",
        "cantidad": "-",
        "movimiento": "Sin Definir",
        "estado": "Sin Definir",
        "codigo_operador": ""
},
{
    url: "/segmentos",
    "id": "",
    "tipo": "Sin Definir",
    "denominacion": "Sin Definir"
},
{
    url: "/cheques/estados",
    "id": "",
    "estado_nombre": "Sin Definir"
},
{
    url: "/cheques?ordering=number",
    extra: ["ordering", "id", "estado__estado_nombre", "instrumento__tipo", "moneda__denominacion", "segmento__denominacion", "monto__lte", "monto__gte", "banco__codigo_banco", "primera_negociacion", "pyme", "fecha_de_pago__gte", "fecha_de_pago__lte", "cuit_librador", "comitente_presentante__cuit", "comitente_tenedor__cuit", "codigo", "sucursal", "plaza", "nro_cheque", "cuenta", "echeq_id"],
    "id": "",
    "cuenta": "-",
    "sucursal": "-",
    "plaza": "-",
    "cuit_librador": "-",
    "monto": "-",
    "endosos": "",
    "comitente_presentante": {
        "cuit": "-",
        "nro_comitente": "-",
        "exceptuado": null,
        "id": "",
        "nombre": "Sin Definir",
        "persona_juridica": null,
        "codigo_operador": {
          "id": "",
          "cat_iva_id": "",
          "cat_iva": {
            "id": "",
            "nombre": "Sin Definir"
          },
          "provincia_id": "",
          "provincia": {
            "id": "",
            "provincia_localidades": [
              {
                "id": "",
                "provincia": "Sin Definir",
                "nombre": "Sin Definir"
              }
            ],
            "nombre": "Sin Definir"
          },
          "localidad_id": "",
          "localidad": {
            "id": "",
            "provincia": "Sin Definir",
            "nombre": "Sin Definir"
          },
          "cnv_categ_id": "",
          "cnv_categ": {
            "id": "",
            "nombre": "Sin Definir"
          },
          "tipo_cliente_id": "",
          "tipo_cliente": {
            "id": "",
            "nombre_tipo": "Sin Definir"
          },
          "razon_social": "Sin Definir",
          "cuit": "-",
          "domicilio": "Sin Definir",
          "telefono": "Sin Definir",
          "email_dif_pub": "Sin Definir",
          "email_institucional": "Sin Definir",
          "sitio_web": "Sin Definir",
          "fec_creacion": "-",
          "fec_actualiz": "-",
          "activo": null,
          "cod_caja_cpd": "",
          "cod_caja_val": "",
          "membresia": null,
          "codigo_op_old": ""
        },
        "is_active": null
      },
    "comitente_tenedor": {
        "cuit": "-",
        "nro_comitente": "-",
        "exceptuado": null,
        "id": "",
        "nombre": "Sin Definir",
        "persona_juridica": null,
        "codigo_operador": {
          "id": "",
          "cat_iva_id": "",
          "cat_iva": {
            "id": "",
            "nombre": "Sin Definir"
          },
          "provincia_id": "",
          "provincia": {
            "id": "",
            "provincia_localidades": [
              {
                "id": "",
                "provincia": "Sin Definir",
                "nombre": "Sin Definir"
              }
            ],
            "nombre": "Sin Definir"
          },
          "localidad_id": "",
          "localidad": {
            "id": "",
            "provincia": "Sin Definir",
            "nombre": "Sin Definir"
          },
          "cnv_categ_id": "",
          "cnv_categ": {
            "id": "",
            "nombre": "Sin Definir"
          },
          "tipo_cliente_id": "",
          "tipo_cliente": {
            "id": "",
            "nombre_tipo": "Sin Definir"
          },
          "razon_social": "Sin Definir",
          "cuit": "-",
          "domicilio": "Sin Definir",
          "telefono": "Sin Definir",
          "email_dif_pub": "Sin Definir",
          "email_institucional": "Sin Definir",
          "sitio_web": "Sin Definir",
          "fec_creacion": "-",
          "fec_actualiz": "-",
          "activo": null,
          "cod_caja_cpd": "",
          "cod_caja_val": "",
          "membresia": null,
          "codigo_op_old": ""
        },
        "is_active": null
      },
    "banco": {
        "id": "",
        "codigo_banco": "Sin Definir",
        "nombre_banco": "Sin Definir",
        "abreviatura_nombre_banco": "Sin Definir",
        "is_active": null
    },
    "segmento": {
        "id": "",
        "tipo": "Sin Definir",
        "denominacion": "Sin Definir"
    },
    "custodio": "Sin Definir",
    "usuario": {
        "id": "",
        "cliente": {
        "id": "",
        "cat_iva_id": "",
        "cat_iva": {
        "id": "",
        "nombre": "Sin Definir"
        },
        "provincia_id": "",
        "provincia": {
        "id": "",
        "provincia_localidades": [
        {
        "id": "",
        "provincia": "Sin Definir",
        "nombre": "Sin Definir"
        }
        ],
        "nombre": "Sin Definir"
        },
        "localidad_id": "",
        "localidad": {
        "id": "",
        "provincia": "",
        "nombre": "Sin Definir"
        },
        "cnv_categ_id": "",
        "cnv_categ": {
        "id": "",
        "nombre": "Sin Definir"
        },
        "tipo_cliente_id": "",
        "tipo_cliente": {
        "id": "",
        "nombre_tipo": "Sin Definir"
        },
        "razon_social": "Sin Definir",
        "cuit": "-",
        "domicilio": "Sin Definir",
        "telefono": "Sin Definir",
        "email_dif_pub": "Sin Definir",
        "email_institucional": "Sin Definir",
        "sitio_web": "Sin Definir",
        "fec_creacion": "-",
        "fec_actualiz": "-",
        "activo": null,
        "cod_caja_cpd": "-",
        "cod_caja_val": "-",
        "membresia": null,
        "codigo_op_old": "-"
        },
        "cliente_id": "",
        "user_type": {
        "id": "",
        "name": "Sin Definir"
        },
        "user_type_id": "",
        "user_params_id": {
        "property1": "Sin Definir",
        "property2": "Sin Definir"
        },
        "user_params": null,
        "last_login": "-",
        "first_name": "Sin Definir",
        "last_name": "Sin Definir",
        "is_staff": null,
        "is_active": null,
        "date_joined": "-",
        "email": "Sin Definir",
        "date_modified": "-",
        "photo": "https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png",
        "admin": null,
        "admin_cupos": null,
        "admin_dma": null,
        "cuit": "-",
        caduca: "-"
    },
    "instrumento": {
        "id": "",
        "tipo": "Sin Definir",
        "descripcion": "Sin Definir",
        "derecho_de_mercado_comprador": "-",
        "derecho_de_mercado_vendedor": "-",
        "iva_de_mercado_comprador": "-",
        "iva_de_mercado_vendedor": "-",
        "base_de_prorrateo": "-",
        "monto_minimo": "-",
        "monto_maximo": null,
        "is_active": null
    },
    "moneda": {
        "id": "",
        "denominacion": "Sin Definir",
        "signo": "-"
    },
    "lote": {
      "lote_id": "",
      "estado": "Sin Definir",
      "subasta": {
          "id": ""
      }
    },
    "remesa": "-",
    "estado": {
        "id": "",
        "estado_nombre": "Sin Definir"
    },
    "certificado_pyme": {
      "id": "",
      "razon_social": "Sin Definir",
      "cuit": "-",
      "fecha_vencimiento": "-",
      "condicion": {
          "id": "",
          "nombre": "Sin Definir"
      },
      "documentacion": null,
      "activo": null
    },
    "fecha_emision": "-",
    "fecha_de_pago": "-",
    "fecha_de_cobro": "-",
    "nro_cheque": "-",
    "clausula_no_a_la_orden": "-",
    "endosado_sin_recurso": "-",
    "primera_negociacion": "-",
    "expone_librador": "-",
    "pyme": "-",
    "echeq_id": "-",
    "codigo": "-",
    "svs": "-"
},
{
  url: "/cheques/id",
  extra: ["ordering", "id", "estado__estado_nombre", "instrumento__tipo", "moneda__denominacion", "segmento__denominacion", "monto__lte", "monto__gte", "banco__codigo_banco", "primera_negociacion", "pyme", "fecha_de_pago__gte", "fecha_de_pago__lte", "cuit_librador", "comitente_presentante__cuit", "comitente_tenedor__cuit", "codigo", "sucursal", "plaza", "nro_cheque", "cuenta", "echeq_id"],
  "id": "",
  "cuenta": "-",
  "sucursal": "-",
  "plaza": "-",
  "cuit_librador": "-",
  "monto": "-",
  "endosos": "",
  "comitente_presentante": {
      "cuit": "-",
      "nro_comitente": "-",
      "exceptuado": null,
      "id": "",
      "nombre": "Sin Definir",
      "persona_juridica": null,
      "codigo_operador": {
        "id": "",
        "cat_iva_id": "",
        "cat_iva": {
          "id": "",
          "nombre": "Sin Definir"
        },
        "provincia_id": "",
        "provincia": {
          "id": "",
          "provincia_localidades": [
            {
              "id": "",
              "provincia": "Sin Definir",
              "nombre": "Sin Definir"
            }
          ],
          "nombre": "Sin Definir"
        },
        "localidad_id": "",
        "localidad": {
          "id": "",
          "provincia": "Sin Definir",
          "nombre": "Sin Definir"
        },
        "cnv_categ_id": "",
        "cnv_categ": {
          "id": "",
          "nombre": "Sin Definir"
        },
        "tipo_cliente_id": "",
        "tipo_cliente": {
          "id": "",
          "nombre_tipo": "Sin Definir"
        },
        "razon_social": "Sin Definir",
        "cuit": "-",
        "domicilio": "Sin Definir",
        "telefono": "Sin Definir",
        "email_dif_pub": "Sin Definir",
        "email_institucional": "Sin Definir",
        "sitio_web": "Sin Definir",
        "fec_creacion": "-",
        "fec_actualiz": "-",
        "activo": null,
        "cod_caja_cpd": "",
        "cod_caja_val": "",
        "membresia": null,
        "codigo_op_old": ""
      },
      "is_active": null
    },
  "comitente_tenedor": {
      "cuit": "-",
      "nro_comitente": "-",
      "exceptuado": null,
      "id": "",
      "nombre": "Sin Definir",
      "persona_juridica": null,
      "codigo_operador": {
        "id": "",
        "cat_iva_id": "",
        "cat_iva": {
          "id": "",
          "nombre": "Sin Definir"
        },
        "provincia_id": "",
        "provincia": {
          "id": "",
          "provincia_localidades": [
            {
              "id": "",
              "provincia": "Sin Definir",
              "nombre": "Sin Definir"
            }
          ],
          "nombre": "Sin Definir"
        },
        "localidad_id": "",
        "localidad": {
          "id": "",
          "provincia": "Sin Definir",
          "nombre": "Sin Definir"
        },
        "cnv_categ_id": "",
        "cnv_categ": {
          "id": "",
          "nombre": "Sin Definir"
        },
        "tipo_cliente_id": "",
        "tipo_cliente": {
          "id": "",
          "nombre_tipo": "Sin Definir"
        },
        "razon_social": "Sin Definir",
        "cuit": "-",
        "domicilio": "Sin Definir",
        "telefono": "Sin Definir",
        "email_dif_pub": "Sin Definir",
        "email_institucional": "Sin Definir",
        "sitio_web": "Sin Definir",
        "fec_creacion": "-",
        "fec_actualiz": "-",
        "activo": null,
        "cod_caja_cpd": "",
        "cod_caja_val": "",
        "membresia": null,
        "codigo_op_old": ""
      },
      "is_active": null
    },
  "banco": {
      "id": "",
      "codigo_banco": "Sin Definir",
      "nombre_banco": "Sin Definir",
      "abreviatura_nombre_banco": "Sin Definir",
      "is_active": null
  },
  "segmento": {
      "id": "",
      "tipo": "Sin Definir",
      "denominacion": "Sin Definir"
  },
  "custodio": "Sin Definir",
  "usuario": {
      "id": "",
      "cliente": {
      "id": "",
      "cat_iva_id": "",
      "cat_iva": {
      "id": "",
      "nombre": "Sin Definir"
      },
      "provincia_id": "",
      "provincia": {
      "id": "",
      "provincia_localidades": [
      {
      "id": "",
      "provincia": "Sin Definir",
      "nombre": "Sin Definir"
      }
      ],
      "nombre": "Sin Definir"
      },
      "localidad_id": "",
      "localidad": {
      "id": "",
      "provincia": "",
      "nombre": "Sin Definir"
      },
      "cnv_categ_id": "",
      "cnv_categ": {
      "id": "",
      "nombre": "Sin Definir"
      },
      "tipo_cliente_id": "",
      "tipo_cliente": {
      "id": "",
      "nombre_tipo": "Sin Definir"
      },
      "razon_social": "Sin Definir",
      "cuit": "-",
      "domicilio": "Sin Definir",
      "telefono": "Sin Definir",
      "email_dif_pub": "Sin Definir",
      "email_institucional": "Sin Definir",
      "sitio_web": "Sin Definir",
      "fec_creacion": "-",
      "fec_actualiz": "-",
      "activo": null,
      "cod_caja_cpd": "-",
      "cod_caja_val": "-",
      "membresia": null,
      "codigo_op_old": "-"
      },
      "cliente_id": "",
      "user_type": {
      "id": "",
      "name": "Sin Definir"
      },
      "user_type_id": "",
      "user_params_id": {
      "property1": "Sin Definir",
      "property2": "Sin Definir"
      },
      "user_params": null,
      "last_login": "-",
      "first_name": "Sin Definir",
      "last_name": "Sin Definir",
      "is_staff": null,
      "is_active": null,
      "date_joined": "-",
      "email": "Sin Definir",
      "date_modified": "-",
      "photo": "https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png",
      "admin": null,
      "admin_cupos": null,
      "admin_dma": null,
      "cuit": "-",
      caduca: "-"
  },
  "instrumento": {
      "id": "",
      "tipo": "Sin Definir",
      "descripcion": "Sin Definir",
      "derecho_de_mercado_comprador": "-",
      "derecho_de_mercado_vendedor": "-",
      "iva_de_mercado_comprador": "-",
      "iva_de_mercado_vendedor": "-",
      "base_de_prorrateo": "-",
      "monto_minimo": "-",
      "monto_maximo": null,
      "is_active": null
  },
  "moneda": {
      "id": "",
      "denominacion": "Sin Definir",
      "signo": "-"
  },
  "lote": {
    "lote_id": "",
    "estado": "Sin Definir",
    "subasta": {
        "id": ""
    }
  },
  "remesa": "-",
  "estado": {
      "id": "",
      "estado_nombre": "Sin Definir"
  },
  "certificado_pyme": {
    "id": "",
    "razon_social": "Sin Definir",
    "cuit": "-",
    "fecha_vencimiento": "-",
    "condicion": {
        "id": "",
        "nombre": "Sin Definir"
    },
    "documentacion": null,
    "activo": null
  },
  "fecha_emision": "-",
  "fecha_de_pago": "-",
  "fecha_de_cobro": "-",
  "nro_cheque": "-",
  "clausula_no_a_la_orden": null,
  "endosado_sin_recurso": null,
  "primera_negociacion": null,
  "expone_librador": null,
  "pyme": null,
  "echeq_id": null,
  "codigo": null,
  "svs": null
},
{
    url: "/lotes?ordering=number",
    extra: ["id", "estado"],
    "id": "",
    "agente_presentante": "-",
    "moneda": {
        "id": "",
        "denominacion": "Sin Definir",
        "signo": "-"
    },
    "cheques_lote": [{
        "id": "",
    "cuenta": "-",
    "sucursal": "-",
    "plaza": "-",
    "cuit_librador": "-",
    "monto": "-",
    "endosos": "",
    "comitente_presentante": {
        "cuit": "-",
        "nro_comitente": "-",
        "exceptuado": null,
        "id": "",
        "nombre": "Sin Definir",
        "persona_juridica": null,
        "codigo_operador": {
          "id": "",
          "cat_iva_id": "",
          "cat_iva": {
            "id": "",
            "nombre": "Sin Definir"
          },
          "provincia_id": "",
          "provincia": {
            "id": "",
            "provincia_localidades": [
              {
                "id": "",
                "provincia": "Sin Definir",
                "nombre": "Sin Definir"
              }
            ],
            "nombre": "Sin Definir"
          },
          "localidad_id": "",
          "localidad": {
            "id": "",
            "provincia": "Sin Definir",
            "nombre": "Sin Definir"
          },
          "cnv_categ_id": "",
          "cnv_categ": {
            "id": "",
            "nombre": "Sin Definir"
          },
          "tipo_cliente_id": "",
          "tipo_cliente": {
            "id": "",
            "nombre_tipo": "Sin Definir"
          },
          "razon_social": "Sin Definir",
          "cuit": "-",
          "domicilio": "Sin Definir",
          "telefono": "Sin Definir",
          "email_dif_pub": "Sin Definir",
          "email_institucional": "Sin Definir",
          "sitio_web": "Sin Definir",
          "fec_creacion": "-",
          "fec_actualiz": "-",
          "activo": null,
          "cod_caja_cpd": "",
          "cod_caja_val": "",
          "membresia": null,
          "codigo_op_old": ""
        },
        "is_active": null
      },
    "comitente_tenedor": {
        "cuit": "-",
        "nro_comitente": "-",
        "exceptuado": null,
        "id": "",
        "nombre": "Sin Definir",
        "persona_juridica": null,
        "codigo_operador": {
          "id": "",
          "cat_iva_id": "",
          "cat_iva": {
            "id": "",
            "nombre": "Sin Definir"
          },
          "provincia_id": "",
          "provincia": {
            "id": "",
            "provincia_localidades": [
              {
                "id": "",
                "provincia": "Sin Definir",
                "nombre": "Sin Definir"
              }
            ],
            "nombre": "Sin Definir"
          },
          "localidad_id": "",
          "localidad": {
            "id": "",
            "provincia": "Sin Definir",
            "nombre": "Sin Definir"
          },
          "cnv_categ_id": "",
          "cnv_categ": {
            "id": "",
            "nombre": "Sin Definir"
          },
          "tipo_cliente_id": "",
          "tipo_cliente": {
            "id": "",
            "nombre_tipo": "Sin Definir"
          },
          "razon_social": "Sin Definir",
          "cuit": "-",
          "domicilio": "Sin Definir",
          "telefono": "Sin Definir",
          "email_dif_pub": "Sin Definir",
          "email_institucional": "Sin Definir",
          "sitio_web": "Sin Definir",
          "fec_creacion": "-",
          "fec_actualiz": "-",
          "activo": null,
          "cod_caja_cpd": "",
          "cod_caja_val": "",
          "membresia": null,
          "codigo_op_old": ""
        },
        "is_active": null
      },
    "banco": {
        "id": "",
        "codigo_banco": "Sin Definir",
        "nombre_banco": "Sin Definir",
        "abreviatura_nombre_banco": "Sin Definir",
        "is_active": null
    },
    "segmento": {
        "id": "",
        "tipo": "Sin Definir",
        "denominacion": "Sin Definir"
    },
    "custodio": "Sin Definir",
    "usuario": {
        "id": "",
        "cliente": {
        "id": "",
        "cat_iva_id": "",
        "cat_iva": {
        "id": "",
        "nombre": "Sin Definir"
        },
        "provincia_id": "",
        "provincia": {
        "id": "",
        "provincia_localidades": [
        {
        "id": "",
        "provincia": "Sin Definir",
        "nombre": "Sin Definir"
        }
        ],
        "nombre": "Sin Definir"
        },
        "localidad_id": "",
        "localidad": {
        "id": "",
        "provincia": "",
        "nombre": "Sin Definir"
        },
        "cnv_categ_id": "",
        "cnv_categ": {
        "id": "",
        "nombre": "Sin Definir"
        },
        "tipo_cliente_id": "",
        "tipo_cliente": {
        "id": "",
        "nombre_tipo": "Sin Definir"
        },
        "razon_social": "Sin Definir",
        "cuit": "-",
        "domicilio": "Sin Definir",
        "telefono": "Sin Definir",
        "email_dif_pub": "Sin Definir",
        "email_institucional": "Sin Definir",
        "sitio_web": "Sin Definir",
        "fec_creacion": "-",
        "fec_actualiz": "-",
        "activo": null,
        "cod_caja_cpd": "-",
        "cod_caja_val": "-",
        "membresia": null,
        "codigo_op_old": "-"
        },
        "cliente_id": "",
        "user_type": {
        "id": "",
        "name": "Sin Definir"
        },
        "user_type_id": "",
        "user_params_id": {
        "property1": "Sin Definir",
        "property2": "Sin Definir"
        },
        "user_params": null,
        "last_login": "-",
        "first_name": "Sin Definir",
        "last_name": "Sin Definir",
        "is_staff": null,
        "is_active": null,
        "date_joined": "-",
        "email": "Sin Definir",
        "date_modified": "-",
        "photo": "https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png",
        "admin": null,
        "admin_cupos": null,
        "admin_dma": null,
        "cuit": "-",
        caduca: "-"
    },
    "instrumento": {
        "id": "",
        "tipo": "Sin Definir",
        "descripcion": "Sin Definir",
        "derecho_de_mercado_comprador": "-",
        "derecho_de_mercado_vendedor": "-",
        "iva_de_mercado_comprador": "-",
        "iva_de_mercado_vendedor": "-",
        "base_de_prorrateo": "-",
        "monto_minimo": "-",
        "monto_maximo": null,
        "is_active": null
    },
    "moneda": {
        "id": "",
        "denominacion": "Sin Definir",
        "signo": "-"
    },
    "lote": null,
    "remesa": "-",
    "estado": {
        "id": "",
        "estado_nombre": "Sin Definir"
    },
    "certificado_pyme": null,
    "fecha_emision": "-",
    "fecha_de_pago": "-",
    "fecha_de_cobro": "-",
    "nro_cheque": "-",
    "clausula_no_a_la_orden": null,
    "endosado_sin_recurso": null,
    "primera_negociacion": null,
    "expone_librador": null,
    "pyme": null,
    "echeq_id": null,
    "codigo": null,
    "svs": null
    }],
    "lote_details": [
        {
            "id": "",
            "segmento": {
                "id": "",
                "tipo": "Directo",
                "denominacion": "Cadena de Valor"
            },
            "id_cheque": "",
            "nro_cheque": "-",
            "monto": "-",
            "banco": null,
            "sucursal": "-",
            "plaza": "-",
            "endosos": "-",
            "comitente_tenedor_cuit": "-",
            "comitente_tenedor_razon_social": "Sin Definir",
            "comitente_tenedor_nro_comitente": "-",
            "comitente_tenedor_exceptuado": null,
            "comitente_presentante_cuit": "-",
            "comitente_presentante_razon_social": "Sin Definir",
            "comitente_presentante_nro_comitente": "-",
            "comitente_presentante_exceptuado": null,
            "fecha_emision": "-",
            "fecha_de_pago": "-",
            "fecha_de_cobro": "-",
            "cuit_librador": "-",
            "cuenta": "-",
            "clausula_no_a_la_orden": null,
            "endosado_sin_recurso": null,
            "fecha_vencimiento": null,
            "primera_negociacion": null,
            "expone_librador": null,
            "pyme": null,
            "svs": null,
            "codigo_cheque": "-",
            "lote": "-",
            "custodio": "Sin Definir",
            "instrumento": "Sin Definir",
            "moneda": "-"
        }
    ],
    "estado": "Sin Definir",
    "monto": "-",
    "usuario": {
        "id": "",
        "cliente": {
        "id": "",
        "cat_iva_id": "",
        "cat_iva": {
        "id": "",
        "nombre": "Sin Definir"
        },
        "provincia_id": "",
        "provincia": {
        "id": "",
        "provincia_localidades": [
        {
        "id": "",
        "provincia": "Sin Definir",
        "nombre": "Sin Definir"
        }
        ],
        "nombre": "Sin Definir"
        },
        "localidad_id": "",
        "localidad": {
        "id": "",
        "provincia": "",
        "nombre": "Sin Definir"
        },
        "cnv_categ_id": "",
        "cnv_categ": {
        "id": "",
        "nombre": "Sin Definir"
        },
        "tipo_cliente_id": "",
        "tipo_cliente": {
        "id": "",
        "nombre_tipo": "Sin Definir"
        },
        "razon_social": "Sin Definir",
        "cuit": "-",
        "domicilio": "Sin Definir",
        "telefono": "Sin Definir",
        "email_dif_pub": "Sin Definir",
        "email_institucional": "Sin Definir",
        "sitio_web": "Sin Definir",
        "fec_creacion": "-",
        "fec_actualiz": "-",
        "activo": null,
        "cod_caja_cpd": "-",
        "cod_caja_val": "-",
        "membresia": null,
        "codigo_op_old": "-"
        },
        "cliente_id": "",
        "user_type": {
        "id": "",
        "name": "Sin Definir"
        },
        "user_type_id": "",
        "user_params_id": {
        "property1": "Sin Definir",
        "property2": "Sin Definir"
        },
        "user_params": null,
        "last_login": "-",
        "first_name": "Sin Definir",
        "last_name": "Sin Definir",
        "is_staff": null,
        "is_active": null,
        "date_joined": "-",
        "email": "Sin Definir",
        "date_modified": "-",
        "photo": "https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png",
        "admin": null,
        "admin_cupos": null,
        "admin_dma": null,
        "cuit": "-",
        caduca: "-"
    },
    "segmento": {
        "id": "",
        "tipo": "Sin Definir",
        "denominacion": "Sin Definir"
    },
    "custodio": "Sin Definir",
    "instrumento": {
        "id": "",
        "tipo": "Sin Definir",
        "descripcion": "Sin Definir",
        "derecho_de_mercado_comprador": "-",
        "derecho_de_mercado_vendedor": "-",
        "iva_de_mercado_comprador": "-",
        "iva_de_mercado_vendedor": "-",
        "base_de_prorrateo": "-",
        "monto_minimo": null,
        "monto_maximo": null,
        "is_active": null
    },
    "svs": null
},
{
  url: "/lotes/id",
  "id": "",
  "agente_presentante": "-",
  "moneda": {
      "id": "",
      "denominacion": "Sin Definir",
      "signo": "-"
  },
  "cheques_lote": [{
      "id": "",
  "cuenta": "-",
  "sucursal": "-",
  "plaza": "-",
  "cuit_librador": "-",
  "monto": "-",
  "endosos": "",
  "comitente_presentante": {
      "cuit": "-",
      "nro_comitente": "-",
      "exceptuado": null,
      "id": "",
      "nombre": "Sin Definir",
      "persona_juridica": null,
      "codigo_operador": {
        "id": "",
        "cat_iva_id": "",
        "cat_iva": {
          "id": "",
          "nombre": "Sin Definir"
        },
        "provincia_id": "",
        "provincia": {
          "id": "",
          "provincia_localidades": [
            {
              "id": "",
              "provincia": "Sin Definir",
              "nombre": "Sin Definir"
            }
          ],
          "nombre": "Sin Definir"
        },
        "localidad_id": "",
        "localidad": {
          "id": "",
          "provincia": "Sin Definir",
          "nombre": "Sin Definir"
        },
        "cnv_categ_id": "",
        "cnv_categ": {
          "id": "",
          "nombre": "Sin Definir"
        },
        "tipo_cliente_id": "",
        "tipo_cliente": {
          "id": "",
          "nombre_tipo": "Sin Definir"
        },
        "razon_social": "Sin Definir",
        "cuit": "-",
        "domicilio": "Sin Definir",
        "telefono": "Sin Definir",
        "email_dif_pub": "Sin Definir",
        "email_institucional": "Sin Definir",
        "sitio_web": "Sin Definir",
        "fec_creacion": "-",
        "fec_actualiz": "-",
        "activo": null,
        "cod_caja_cpd": "",
        "cod_caja_val": "",
        "membresia": null,
        "codigo_op_old": ""
      },
      "is_active": null
    },
  "comitente_tenedor": {
      "cuit": "-",
      "nro_comitente": "-",
      "exceptuado": null,
      "id": "",
      "nombre": "Sin Definir",
      "persona_juridica": null,
      "codigo_operador": {
        "id": "",
        "cat_iva_id": "",
        "cat_iva": {
          "id": "",
          "nombre": "Sin Definir"
        },
        "provincia_id": "",
        "provincia": {
          "id": "",
          "provincia_localidades": [
            {
              "id": "",
              "provincia": "Sin Definir",
              "nombre": "Sin Definir"
            }
          ],
          "nombre": "Sin Definir"
        },
        "localidad_id": "",
        "localidad": {
          "id": "",
          "provincia": "Sin Definir",
          "nombre": "Sin Definir"
        },
        "cnv_categ_id": "",
        "cnv_categ": {
          "id": "",
          "nombre": "Sin Definir"
        },
        "tipo_cliente_id": "",
        "tipo_cliente": {
          "id": "",
          "nombre_tipo": "Sin Definir"
        },
        "razon_social": "Sin Definir",
        "cuit": "-",
        "domicilio": "Sin Definir",
        "telefono": "Sin Definir",
        "email_dif_pub": "Sin Definir",
        "email_institucional": "Sin Definir",
        "sitio_web": "Sin Definir",
        "fec_creacion": "-",
        "fec_actualiz": "-",
        "activo": null,
        "cod_caja_cpd": "",
        "cod_caja_val": "",
        "membresia": null,
        "codigo_op_old": ""
      },
      "is_active": null
    },
  "banco": {
      "id": "",
      "codigo_banco": "Sin Definir",
      "nombre_banco": "Sin Definir",
      "abreviatura_nombre_banco": "Sin Definir",
      "is_active": null
  },
  "segmento": {
      "id": "",
      "tipo": "Sin Definir",
      "denominacion": "Sin Definir"
  },
  "custodio": "Sin Definir",
  "usuario": {
      "id": "",
      "cliente": {
      "id": "",
      "cat_iva_id": "",
      "cat_iva": {
      "id": "",
      "nombre": "Sin Definir"
      },
      "provincia_id": "",
      "provincia": {
      "id": "",
      "provincia_localidades": [
      {
      "id": "",
      "provincia": "Sin Definir",
      "nombre": "Sin Definir"
      }
      ],
      "nombre": "Sin Definir"
      },
      "localidad_id": "",
      "localidad": {
      "id": "",
      "provincia": "",
      "nombre": "Sin Definir"
      },
      "cnv_categ_id": "",
      "cnv_categ": {
      "id": "",
      "nombre": "Sin Definir"
      },
      "tipo_cliente_id": "",
      "tipo_cliente": {
      "id": "",
      "nombre_tipo": "Sin Definir"
      },
      "razon_social": "Sin Definir",
      "cuit": "-",
      "domicilio": "Sin Definir",
      "telefono": "Sin Definir",
      "email_dif_pub": "Sin Definir",
      "email_institucional": "Sin Definir",
      "sitio_web": "Sin Definir",
      "fec_creacion": "-",
      "fec_actualiz": "-",
      "activo": null,
      "cod_caja_cpd": "-",
      "cod_caja_val": "-",
      "membresia": null,
      "codigo_op_old": "-"
      },
      "cliente_id": "",
      "user_type": {
      "id": "",
      "name": "Sin Definir"
      },
      "user_type_id": "",
      "user_params_id": {
      "property1": "Sin Definir",
      "property2": "Sin Definir"
      },
      "user_params": null,
      "last_login": "-",
      "first_name": "Sin Definir",
      "last_name": "Sin Definir",
      "is_staff": null,
      "is_active": null,
      "date_joined": "-",
      "email": "Sin Definir",
      "date_modified": "-",
      "photo": "https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png",
      "admin": null,
      "admin_cupos": null,
      "admin_dma": null,
      "cuit": "-",
      caduca: "-"
  },
  "instrumento": {
      "id": "",
      "tipo": "Sin Definir",
      "descripcion": "Sin Definir",
      "derecho_de_mercado_comprador": "-",
      "derecho_de_mercado_vendedor": "-",
      "iva_de_mercado_comprador": "-",
      "iva_de_mercado_vendedor": "-",
      "base_de_prorrateo": "-",
      "monto_minimo": "-",
      "monto_maximo": null,
      "is_active": null
  },
  "moneda": {
      "id": "",
      "denominacion": "Sin Definir",
      "signo": "-"
  },
  "lote": null,
  "remesa": "-",
  "estado": {
      "id": "",
      "estado_nombre": "Sin Definir"
  },
  "certificado_pyme": null,
  "fecha_emision": "-",
  "fecha_de_pago": "-",
  "fecha_de_cobro": "-",
  "nro_cheque": "-",
  "clausula_no_a_la_orden": null,
  "endosado_sin_recurso": null,
  "primera_negociacion": null,
  "expone_librador": null,
  "pyme": null,
  "echeq_id": null,
  "codigo": null,
  "svs": null
  }],
  "lote_details": [
      {
          "id": "",
          "segmento": {
              "id": "",
              "tipo": "Directo",
              "denominacion": "Cadena de Valor"
          },
          "id_cheque": "",
          "nro_cheque": "-",
          "monto": "-",
          "banco": null,
          "sucursal": "-",
          "plaza": "-",
          "endosos": "-",
          "comitente_tenedor_cuit": "-",
          "comitente_tenedor_razon_social": "Sin Definir",
          "comitente_tenedor_nro_comitente": "-",
          "comitente_tenedor_exceptuado": null,
          "comitente_presentante_cuit": "-",
          "comitente_presentante_razon_social": "Sin Definir",
          "comitente_presentante_nro_comitente": "-",
          "comitente_presentante_exceptuado": null,
          "fecha_emision": "-",
          "fecha_de_pago": "-",
          "fecha_de_cobro": "-",
          "cuit_librador": "-",
          "cuenta": "-",
          "clausula_no_a_la_orden": null,
          "endosado_sin_recurso": null,
          "fecha_vencimiento": null,
          "primera_negociacion": null,
          "expone_librador": null,
          "pyme": null,
          "svs": null,
          "codigo_cheque": "-",
          "lote": "-",
          "custodio": "Sin Definir",
          "instrumento": "Sin Definir",
          "moneda": "-"
      }
  ],
  "estado": "Sin Definir",
  "monto": "-",
  "usuario": {
      "id": "",
      "cliente": {
      "id": "",
      "cat_iva_id": "",
      "cat_iva": {
      "id": "",
      "nombre": "Sin Definir"
      },
      "provincia_id": "",
      "provincia": {
      "id": "",
      "provincia_localidades": [
      {
      "id": "",
      "provincia": "Sin Definir",
      "nombre": "Sin Definir"
      }
      ],
      "nombre": "Sin Definir"
      },
      "localidad_id": "",
      "localidad": {
      "id": "",
      "provincia": "",
      "nombre": "Sin Definir"
      },
      "cnv_categ_id": "",
      "cnv_categ": {
      "id": "",
      "nombre": "Sin Definir"
      },
      "tipo_cliente_id": "",
      "tipo_cliente": {
      "id": "",
      "nombre_tipo": "Sin Definir"
      },
      "razon_social": "Sin Definir",
      "cuit": "-",
      "domicilio": "Sin Definir",
      "telefono": "Sin Definir",
      "email_dif_pub": "Sin Definir",
      "email_institucional": "Sin Definir",
      "sitio_web": "Sin Definir",
      "fec_creacion": "-",
      "fec_actualiz": "-",
      "activo": null,
      "cod_caja_cpd": "-",
      "cod_caja_val": "-",
      "membresia": null,
      "codigo_op_old": "-"
      },
      "cliente_id": "",
      "user_type": {
      "id": "",
      "name": "Sin Definir"
      },
      "user_type_id": "",
      "user_params_id": {
      "property1": "Sin Definir",
      "property2": "Sin Definir"
      },
      "user_params": null,
      "last_login": "-",
      "first_name": "Sin Definir",
      "last_name": "Sin Definir",
      "is_staff": null,
      "is_active": null,
      "date_joined": "-",
      "email": "Sin Definir",
      "date_modified": "-",
      "photo": "https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png",
      "admin": null,
      "admin_cupos": null,
      "admin_dma": null,
      "cuit": "-",
      caduca: "-"
  },
  "segmento": {
      "id": "",
      "tipo": "Sin Definir",
      "denominacion": "Sin Definir"
  },
  "custodio": "Sin Definir",
  "instrumento": {
      "id": "",
      "tipo": "Sin Definir",
      "descripcion": "Sin Definir",
      "derecho_de_mercado_comprador": "-",
      "derecho_de_mercado_vendedor": "-",
      "iva_de_mercado_comprador": "-",
      "iva_de_mercado_vendedor": "-",
      "base_de_prorrateo": "-",
      "monto_minimo": null,
      "monto_maximo": null,
      "is_active": null
  },
  "svs": null
},
{
    url: "/cotizaciones",
    extra: ["nro_esp", "search", "nom_tipo"],
    "id": "",
    "fecha": "-",
    "nro_esp": "-",
    "cod_esp": "-",
    "nom_tipo": "-",
    "cod_plazo": "-",
    "precio_por": "-",
    "cierre_anterior": "-",
    "aforo": "-",
    "precio_aforado": "-",
    "fec_creacion": "-",
    "fec_actualiz": "-",
    "is_active": null
},
{
    url: "/cotizaciones/logs?ordering=number",
    "id": "",
    "fecha_consulta": "-",
    "usuario": "Sin Definir"
},
{
    url: "/auth",
    "refresh": "",
    "access": "",
    "user_id": ""
},
{
  url: "/users/logs/id",
  "id": "",
  "user": "-",
  "fecha_ingreso": "-",
  "hora_ingreso": "-",
  "tipo": "-",
  "client_ip": null
},
{
    url: "/users/id",
    "id": "",
    "cliente": {
        "id": 0,
        "cat_iva_id": 0,
        "cat_iva": {
          "id": 0,
          "nombre": "Sin Definir"
        },
        "provincia_id": 0,
        "provincia": {
          "id": 0,
          "provincia_localidades": [
            {
              "id": 0,
              "provincia": 0,
              "nombre": "Sin Definir"
            }
          ],
          "nombre": "Sin Definir"
        },
        "localidad_id": 0,
        "localidad": {
          "id": 0,
          "provincia": 0,
          "nombre": "Sin Definir"
        },
        "cnv_categ_id": 0,
        "cnv_categ": {
          "id": 0,
          "nombre": "Sin Definir"
        },
        "tipo_cliente_id": 0,
        "tipo_cliente": {
          "id": 0,
          "nombre_tipo": "Sin Definir"
        },
        "razon_social": "Sin Definir",
        "cuit": "-",
        "domicilio": "Sin Definir",
        "telefono": "Sin Definir",
        "email_dif_pub": "Sin Definir",
        "email_institucional": "Sin Definir",
        "sitio_web": "Sin Definir",
        "fec_creacion": "-",
        "fec_actualiz": "-",
        "activo": null,
        "cod_caja_cpd": "-",
        "cod_caja_val": "-",
        "membresia": null,
        "codigo_op_old": "-"
},
    "user_type": {
        "id": "",
        "name": "Sin Definir",
        "id_perfil": null
    },
    "user_params": null,
    "last_login": "-",
    "first_name": "Sin Definir",
    "last_name": "Sin Definir",
    "is_staff": null,
    "is_active": null,
    "date_joined": "-",
    "email": "Sin Definir",
    "date_modified": "-",
    "photo": "https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png",
    "admin": null,
    "admin_cupos": null,
    "admin_dma": null,
    "cuit": "-",
    "username": "-"
},
{
    url: "/api/padron",
    extra: ["cuil"],
    "cuit_cuil": 20424271854,
    "denominacion": "SAEZ GONZALEZ ALEJANDRO JAVIER",
    "actividad": "",
    "marca_baja": "",
    "cuit_cuil_reemplazo": "530090",
    "fallecimiento": ""
},
{
    url: "/api/deudores",
    extra: ["cuil"],
    "codigo_entidad": "-",
    "fecha_informacion": "-",
    "tipo_identificacion": "-",
    "n_identificacion": "-",
    "actividad": "-",
    "situacion": "-",
    "prestamos": "-",
    "participaciones": "-",
    "garantias": "-",
    "otros_conceptos": "-",
    "garantias_preferidas_a": "-",
    "garantias_preferidas_b": "-",
    "s_garantias_preferidas": "-",
    "contragarantias_preferidas_a": "-",
    "contragarantias_preferidas_b": "-",
    "s_contragarantias_preferidas": "-",
    "previsiones": "-",
    "deuda_cubierta": "-",
    "proceso_judicial": "-",
    "refinanciaciones": "-",
    "recategorizacion_obligatoria": "-",
    "situacion_juridia": "-",
    "irrecuperables_por_disposicion": "-",
    "dias_atraso": "-"
},
{
    url: "/subasta",
    extra: ["estado", "lote__moneda__denominacion", "plazo", "lote__segmento__denominacion", "lote__instrumento__tipo", "lote__monto__lte", "lote__monto__gte", "ppv__lte", "ppv__gte"],
    "id": "",
    "tasa_venta": "-",
    "origen": "Sin Definir",
    "hora_inicio": "-",
    "hora_cierre": "-",
    "lote": {
        "id": "",
        "agente_presentante": "-",
        "moneda": {
            "id": "",
            "denominacion": "Sin Definir",
            "signo": "-"
        },
        "cheques_lote": [{
            "id": "",
        "cuenta": "-",
        "sucursal": "-",
        "plaza": "-",
        "cuit_librador": "-",
        "monto": "-",
        "endosos": "",
        "comitente_presentante": {
            "cuit": "-",
            "nro_comitente": "-",
            "exceptuado": null,
            "id": "",
            "nombre": "Sin Definir",
            "persona_juridica": null,
            "codigo_operador": {
              "id": "",
              "cat_iva_id": "",
              "cat_iva": {
                "id": "",
                "nombre": "Sin Definir"
              },
              "provincia_id": "",
              "provincia": {
                "id": "",
                "provincia_localidades": [
                  {
                    "id": "",
                    "provincia": "Sin Definir",
                    "nombre": "Sin Definir"
                  }
                ],
                "nombre": "Sin Definir"
              },
              "localidad_id": "",
              "localidad": {
                "id": "",
                "provincia": "Sin Definir",
                "nombre": "Sin Definir"
              },
              "cnv_categ_id": "",
              "cnv_categ": {
                "id": "",
                "nombre": "Sin Definir"
              },
              "tipo_cliente_id": "",
              "tipo_cliente": {
                "id": "",
                "nombre_tipo": "Sin Definir"
              },
              "razon_social": "Sin Definir",
              "cuit": "-",
              "domicilio": "Sin Definir",
              "telefono": "Sin Definir",
              "email_dif_pub": "Sin Definir",
              "email_institucional": "Sin Definir",
              "sitio_web": "Sin Definir",
              "fec_creacion": "-",
              "fec_actualiz": "-",
              "activo": null,
              "cod_caja_cpd": "",
              "cod_caja_val": "",
              "membresia": null,
              "codigo_op_old": ""
            },
            "is_active": null
          },
        "comitente_tenedor": {
            "cuit": "-",
            "nro_comitente": "-",
            "exceptuado": null,
            "id": "",
            "nombre": "Sin Definir",
            "persona_juridica": null,
            "codigo_operador": {
              "id": "",
              "cat_iva_id": "",
              "cat_iva": {
                "id": "",
                "nombre": "Sin Definir"
              },
              "provincia_id": "",
              "provincia": {
                "id": "",
                "provincia_localidades": [
                  {
                    "id": "",
                    "provincia": "Sin Definir",
                    "nombre": "Sin Definir"
                  }
                ],
                "nombre": "Sin Definir"
              },
              "localidad_id": "",
              "localidad": {
                "id": "",
                "provincia": "Sin Definir",
                "nombre": "Sin Definir"
              },
              "cnv_categ_id": "",
              "cnv_categ": {
                "id": "",
                "nombre": "Sin Definir"
              },
              "tipo_cliente_id": "",
              "tipo_cliente": {
                "id": "",
                "nombre_tipo": "Sin Definir"
              },
              "razon_social": "Sin Definir",
              "cuit": "-",
              "domicilio": "Sin Definir",
              "telefono": "Sin Definir",
              "email_dif_pub": "Sin Definir",
              "email_institucional": "Sin Definir",
              "sitio_web": "Sin Definir",
              "fec_creacion": "-",
              "fec_actualiz": "-",
              "activo": null,
              "cod_caja_cpd": "",
              "cod_caja_val": "",
              "membresia": null,
              "codigo_op_old": ""
            },
            "is_active": null
          },
        "banco": {
            "id": "",
            "codigo_banco": "Sin Definir",
            "nombre_banco": "Sin Definir",
            "abreviatura_nombre_banco": "Sin Definir",
            "is_active": null
        },
        "segmento": {
            "id": "",
            "tipo": "Sin Definir",
            "denominacion": "Sin Definir"
        },
        "custodio": "Sin Definir",
        "usuario": {
            "id": "",
            "cliente": {
            "id": "",
            "cat_iva_id": "",
            "cat_iva": {
            "id": "",
            "nombre": "Sin Definir"
            },
            "provincia_id": "",
            "provincia": {
            "id": "",
            "provincia_localidades": [
            {
            "id": "",
            "provincia": "Sin Definir",
            "nombre": "Sin Definir"
            }
            ],
            "nombre": "Sin Definir"
            },
            "localidad_id": "",
            "localidad": {
            "id": "",
            "provincia": "",
            "nombre": "Sin Definir"
            },
            "cnv_categ_id": "",
            "cnv_categ": {
            "id": "",
            "nombre": "Sin Definir"
            },
            "tipo_cliente_id": "",
            "tipo_cliente": {
            "id": "",
            "nombre_tipo": "Sin Definir"
            },
            "razon_social": "Sin Definir",
            "cuit": "-",
            "domicilio": "Sin Definir",
            "telefono": "Sin Definir",
            "email_dif_pub": "Sin Definir",
            "email_institucional": "Sin Definir",
            "sitio_web": "Sin Definir",
            "fec_creacion": "-",
            "fec_actualiz": "-",
            "activo": null,
            "cod_caja_cpd": "-",
            "cod_caja_val": "-",
            "membresia": null,
            "codigo_op_old": "-"
            },
            "cliente_id": "",
            "user_type": {
            "id": "",
            "name": "Sin Definir"
            },
            "user_type_id": "",
            "user_params_id": {
            "property1": "Sin Definir",
            "property2": "Sin Definir"
            },
            "user_params": null,
            "last_login": "-",
            "first_name": "Sin Definir",
            "last_name": "Sin Definir",
            "is_staff": null,
            "is_active": null,
            "date_joined": "-",
            "email": "Sin Definir",
            "date_modified": "-",
            "photo": "https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png",
            "admin": null,
            "admin_cupos": null,
            "admin_dma": null,
            "cuit": "-",
            caduca: "-"
        },
        "instrumento": {
            "id": "",
            "tipo": "Sin Definir",
            "descripcion": "Sin Definir",
            "derecho_de_mercado_comprador": "-",
            "derecho_de_mercado_vendedor": "-",
            "iva_de_mercado_comprador": "-",
            "iva_de_mercado_vendedor": "-",
            "base_de_prorrateo": "-",
            "monto_minimo": "-",
            "monto_maximo": null,
            "is_active": null
        },
        "moneda": {
            "id": "",
            "denominacion": "Sin Definir",
            "signo": "-"
        },
        "lote": null,
        "remesa": "-",
        "estado": {
            "id": "",
            "estado_nombre": "Sin Definir"
        },
        "certificado_pyme": null,
        "fecha_emision": "-",
        "fecha_de_pago": "-",
        "fecha_de_cobro": "-",
        "nro_cheque": "-",
        "clausula_no_a_la_orden": null,
        "endosado_sin_recurso": null,
        "primera_negociacion": null,
        "expone_librador": null,
        "pyme": null,
        "echeq_id": null,
        "codigo": null,
        "svs": null
        }],
        "lote_details": [
            {
                "id": "",
                "segmento": {
                    "id": "",
                    "tipo": "Directo",
                    "denominacion": "Cadena de Valor"
                },
                "id_cheque": "",
                "nro_cheque": "-",
                "monto": "-",
                "banco": null,
                "sucursal": "-",
                "plaza": "-",
                "endosos": "-",
                "comitente_tenedor_cuit": "-",
                "comitente_tenedor_razon_social": "Sin Definir",
                "comitente_tenedor_nro_comitente": "-",
                "comitente_tenedor_exceptuado": null,
                "comitente_presentante_cuit": "-",
                "comitente_presentante_razon_social": "Sin Definir",
                "comitente_presentante_nro_comitente": "-",
                "comitente_presentante_exceptuado": null,
                "fecha_emision": "-",
                "fecha_de_pago": "-",
                "fecha_de_cobro": "-",
                "cuit_librador": "-",
                "cuenta": "-",
                "clausula_no_a_la_orden": null,
                "endosado_sin_recurso": null,
                "fecha_vencimiento": null,
                "primera_negociacion": null,
                "expone_librador": null,
                "pyme": null,
                "svs": null,
                "codigo_cheque": "-",
                "lote": "-",
                "custodio": "Sin Definir",
                "instrumento": "Sin Definir",
                "moneda": "-"
            }
        ],
        "estado": "Sin Definir",
        "monto": "-",
        "usuario": {
            "id": "",
            "cliente": {
            "id": "",
            "cat_iva_id": "",
            "cat_iva": {
            "id": "",
            "nombre": "Sin Definir"
            },
            "provincia_id": "",
            "provincia": {
            "id": "",
            "provincia_localidades": [
            {
            "id": "",
            "provincia": "Sin Definir",
            "nombre": "Sin Definir"
            }
            ],
            "nombre": "Sin Definir"
            },
            "localidad_id": "",
            "localidad": {
            "id": "",
            "provincia": "",
            "nombre": "Sin Definir"
            },
            "cnv_categ_id": "",
            "cnv_categ": {
            "id": "",
            "nombre": "Sin Definir"
            },
            "tipo_cliente_id": "",
            "tipo_cliente": {
            "id": "",
            "nombre_tipo": "Sin Definir"
            },
            "razon_social": "Sin Definir",
            "cuit": "-",
            "domicilio": "Sin Definir",
            "telefono": "Sin Definir",
            "email_dif_pub": "Sin Definir",
            "email_institucional": "Sin Definir",
            "sitio_web": "Sin Definir",
            "fec_creacion": "-",
            "fec_actualiz": "-",
            "activo": null,
            "cod_caja_cpd": "-",
            "cod_caja_val": "-",
            "membresia": null,
            "codigo_op_old": "-"
            },
            "cliente_id": "",
            "user_type": {
            "id": "",
            "name": "Sin Definir"
            },
            "user_type_id": "",
            "user_params_id": {
            "property1": "Sin Definir",
            "property2": "Sin Definir"
            },
            "user_params": null,
            "last_login": "-",
            "first_name": "Sin Definir",
            "last_name": "Sin Definir",
            "is_staff": null,
            "is_active": null,
            "date_joined": "-",
            "email": "Sin Definir",
            "date_modified": "-",
            "photo": "https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png",
            "admin": null,
            "admin_cupos": null,
            "admin_dma": null,
            "cuit": "-",
            caduca: "-"
        },
        "segmento": {
            "id": "",
            "tipo": "Sin Definir",
            "denominacion": "Sin Definir"
        },
        "custodio": "Sin Definir",
        "instrumento": {
            "id": "",
            "tipo": "Sin Definir",
            "descripcion": "Sin Definir",
            "derecho_de_mercado_comprador": "-",
            "derecho_de_mercado_vendedor": "-",
            "iva_de_mercado_comprador": "-",
            "iva_de_mercado_vendedor": "-",
            "base_de_prorrateo": "-",
            "monto_minimo": null,
            "monto_maximo": null,
            "is_active": null
        },
        "svs": null
    },
    "plazo": {
        id: "",
        nombre: "Sin Definir",
        hora_inicio: "-",
        hora_final: "-"
    },
    "usuario": "",
    "estado": "Sin Definir",
    "tasa_comprador": null,
    "borrado": null,
    "ppv": "-"
},
{
    url: "/subasta/id",
    "id": "",
    "agente_vendedor": {
      "cod_op": "",
      "id": ""
    },
    "comprador": null,
    "tasa_venta": null,
    "origen": "Sin Definir",
    "hora_inicio": "-",
    "hora_cierre": "-",
    "hora_concertacion": "-",
    "lote": {
        "id": "",
        "agente_presentante": "-",
        "moneda": {
            "id": "",
            "denominacion": "Sin Definir",
            "signo": "-"
        },
        "cheques_lote": [{
            "id": "",
        "cuenta": "-",
        "sucursal": "-",
        "plaza": "-",
        "cuit_librador": "-",
        "monto": "-",
        "endosos": "",
        "comitente_presentante": {
            "cuit": "-",
            "nro_comitente": "-",
            "exceptuado": null,
            "id": "",
            "nombre": "Sin Definir",
            "persona_juridica": null,
            "codigo_operador": {
              "id": "",
              "cat_iva_id": "",
              "cat_iva": {
                "id": "",
                "nombre": "Sin Definir"
              },
              "provincia_id": "",
              "provincia": {
                "id": "",
                "provincia_localidades": [
                  {
                    "id": "",
                    "provincia": "Sin Definir",
                    "nombre": "Sin Definir"
                  }
                ],
                "nombre": "Sin Definir"
              },
              "localidad_id": "",
              "localidad": {
                "id": "",
                "provincia": "Sin Definir",
                "nombre": "Sin Definir"
              },
              "cnv_categ_id": "",
              "cnv_categ": {
                "id": "",
                "nombre": "Sin Definir"
              },
              "tipo_cliente_id": "",
              "tipo_cliente": {
                "id": "",
                "nombre_tipo": "Sin Definir"
              },
              "razon_social": "Sin Definir",
              "cuit": "-",
              "domicilio": "Sin Definir",
              "telefono": "Sin Definir",
              "email_dif_pub": "Sin Definir",
              "email_institucional": "Sin Definir",
              "sitio_web": "Sin Definir",
              "fec_creacion": "-",
              "fec_actualiz": "-",
              "activo": null,
              "cod_caja_cpd": "",
              "cod_caja_val": "",
              "membresia": null,
              "codigo_op_old": ""
            },
            "is_active": null
          },
        "comitente_tenedor": {
            "cuit": "-",
            "nro_comitente": "-",
            "exceptuado": null,
            "id": "",
            "nombre": "Sin Definir",
            "persona_juridica": null,
            "codigo_operador": {
              "id": "",
              "cat_iva_id": "",
              "cat_iva": {
                "id": "",
                "nombre": "Sin Definir"
              },
              "provincia_id": "",
              "provincia": {
                "id": "",
                "provincia_localidades": [
                  {
                    "id": "",
                    "provincia": "Sin Definir",
                    "nombre": "Sin Definir"
                  }
                ],
                "nombre": "Sin Definir"
              },
              "localidad_id": "",
              "localidad": {
                "id": "",
                "provincia": "Sin Definir",
                "nombre": "Sin Definir"
              },
              "cnv_categ_id": "",
              "cnv_categ": {
                "id": "",
                "nombre": "Sin Definir"
              },
              "tipo_cliente_id": "",
              "tipo_cliente": {
                "id": "",
                "nombre_tipo": "Sin Definir"
              },
              "razon_social": "Sin Definir",
              "cuit": "-",
              "domicilio": "Sin Definir",
              "telefono": "Sin Definir",
              "email_dif_pub": "Sin Definir",
              "email_institucional": "Sin Definir",
              "sitio_web": "Sin Definir",
              "fec_creacion": "-",
              "fec_actualiz": "-",
              "activo": null,
              "cod_caja_cpd": "",
              "cod_caja_val": "",
              "membresia": null,
              "codigo_op_old": ""
            },
            "is_active": null
          },
        "banco": {
            "id": "",
            "codigo_banco": "Sin Definir",
            "nombre_banco": "Sin Definir",
            "abreviatura_nombre_banco": "Sin Definir",
            "is_active": null
        },
        "segmento": {
            "id": "",
            "tipo": "Sin Definir",
            "denominacion": "Sin Definir"
        },
        "custodio": "Sin Definir",
        "usuario": {
            "id": "",
            "cliente": {
            "id": "",
            "cat_iva_id": "",
            "cat_iva": {
            "id": "",
            "nombre": "Sin Definir"
            },
            "provincia_id": "",
            "provincia": {
            "id": "",
            "provincia_localidades": [
            {
            "id": "",
            "provincia": "Sin Definir",
            "nombre": "Sin Definir"
            }
            ],
            "nombre": "Sin Definir"
            },
            "localidad_id": "",
            "localidad": {
            "id": "",
            "provincia": "",
            "nombre": "Sin Definir"
            },
            "cnv_categ_id": "",
            "cnv_categ": {
            "id": "",
            "nombre": "Sin Definir"
            },
            "tipo_cliente_id": "",
            "tipo_cliente": {
            "id": "",
            "nombre_tipo": "Sin Definir"
            },
            "razon_social": "Sin Definir",
            "cuit": "-",
            "domicilio": "Sin Definir",
            "telefono": "Sin Definir",
            "email_dif_pub": "Sin Definir",
            "email_institucional": "Sin Definir",
            "sitio_web": "Sin Definir",
            "fec_creacion": "-",
            "fec_actualiz": "-",
            "activo": null,
            "cod_caja_cpd": "-",
            "cod_caja_val": "-",
            "membresia": null,
            "codigo_op_old": "-"
            },
            "cliente_id": "",
            "user_type": {
            "id": "",
            "name": "Sin Definir"
            },
            "user_type_id": "",
            "user_params_id": {
            "property1": "Sin Definir",
            "property2": "Sin Definir"
            },
            "user_params": null,
            "last_login": "-",
            "first_name": "Sin Definir",
            "last_name": "Sin Definir",
            "is_staff": null,
            "is_active": null,
            "date_joined": "-",
            "email": "Sin Definir",
            "date_modified": "-",
            "photo": "https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png",
            "admin": null,
            "admin_cupos": null,
            "admin_dma": null,
            "cuit": "-",
            caduca: "-"
        },
        "instrumento": {
            "id": "",
            "tipo": "Sin Definir",
            "descripcion": "Sin Definir",
            "derecho_de_mercado_comprador": "-",
            "derecho_de_mercado_vendedor": "-",
            "iva_de_mercado_comprador": "-",
            "iva_de_mercado_vendedor": "-",
            "base_de_prorrateo": "-",
            "monto_minimo": "-",
            "monto_maximo": null,
            "is_active": null
        },
        "moneda": {
            "id": "",
            "denominacion": "Sin Definir",
            "signo": "-"
        },
        "lote": null,
        "remesa": "-",
        "estado": {
            "id": "",
            "estado_nombre": "Sin Definir"
        },
        "certificado_pyme": null,
        "fecha_emision": "-",
        "fecha_de_pago": "-",
        "fecha_de_cobro": "-",
        "nro_cheque": "-",
        "clausula_no_a_la_orden": null,
        "endosado_sin_recurso": null,
        "primera_negociacion": null,
        "expone_librador": null,
        "pyme": null,
        "echeq_id": null,
        "codigo": null,
        "svs": null
        }],
        "lote_details": [
            {
                "id": "",
                "segmento": {
                    "id": "",
                    "tipo": "Directo",
                    "denominacion": "Cadena de Valor"
                },
                "id_cheque": "",
                "nro_cheque": "-",
                "monto": "-",
                "banco": null,
                "sucursal": "-",
                "plaza": "-",
                "endosos": "-",
                "comitente_tenedor_cuit": "-",
                "comitente_tenedor_razon_social": "Sin Definir",
                "comitente_tenedor_nro_comitente": "-",
                "comitente_tenedor_exceptuado": null,
                "comitente_presentante_cuit": "-",
                "comitente_presentante_razon_social": "Sin Definir",
                "comitente_presentante_nro_comitente": "-",
                "comitente_presentante_exceptuado": null,
                "fecha_emision": "-",
                "fecha_de_pago": "-",
                "fecha_de_cobro": "-",
                "cuit_librador": "-",
                "cuenta": "-",
                "clausula_no_a_la_orden": null,
                "endosado_sin_recurso": null,
                "fecha_vencimiento": null,
                "primera_negociacion": null,
                "expone_librador": null,
                "pyme": null,
                "svs": null,
                "codigo_cheque": "-",
                "lote": "-",
                "custodio": "Sin Definir",
                "instrumento": "Sin Definir",
                "moneda": "-"
            }
        ],
        "estado": "Sin Definir",
        "monto": "-",
        "usuario": {
            "id": "",
            "cliente": {
            "id": "",
            "cat_iva_id": "",
            "cat_iva": {
            "id": "",
            "nombre": "Sin Definir"
            },
            "provincia_id": "",
            "provincia": {
            "id": "",
            "provincia_localidades": [
            {
            "id": "",
            "provincia": "Sin Definir",
            "nombre": "Sin Definir"
            }
            ],
            "nombre": "Sin Definir"
            },
            "localidad_id": "",
            "localidad": {
            "id": "",
            "provincia": "",
            "nombre": "Sin Definir"
            },
            "cnv_categ_id": "",
            "cnv_categ": {
            "id": "",
            "nombre": "Sin Definir"
            },
            "tipo_cliente_id": "",
            "tipo_cliente": {
            "id": "",
            "nombre_tipo": "Sin Definir"
            },
            "razon_social": "Sin Definir",
            "cuit": "-",
            "domicilio": "Sin Definir",
            "telefono": "Sin Definir",
            "email_dif_pub": "Sin Definir",
            "email_institucional": "Sin Definir",
            "sitio_web": "Sin Definir",
            "fec_creacion": "-",
            "fec_actualiz": "-",
            "activo": null,
            "cod_caja_cpd": "-",
            "cod_caja_val": "-",
            "membresia": null,
            "codigo_op_old": "-"
            },
            "cliente_id": "",
            "user_type": {
            "id": "",
            "name": "Sin Definir"
            },
            "user_type_id": "",
            "user_params_id": {
            "property1": "Sin Definir",
            "property2": "Sin Definir"
            },
            "user_params": null,
            "last_login": "-",
            "first_name": "Sin Definir",
            "last_name": "Sin Definir",
            "is_staff": null,
            "is_active": null,
            "date_joined": "-",
            "email": "Sin Definir",
            "date_modified": "-",
            "photo": "https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png",
            "admin": null,
            "admin_cupos": null,
            "admin_dma": null,
            "cuit": "-",
            caduca: "-"
        },
        "segmento": {
            "id": "",
            "tipo": "Sin Definir",
            "denominacion": "Sin Definir"
        },
        "custodio": "Sin Definir",
        "instrumento": {
            "id": "",
            "tipo": "Sin Definir",
            "descripcion": "Sin Definir",
            "derecho_de_mercado_comprador": "-",
            "derecho_de_mercado_vendedor": "-",
            "iva_de_mercado_comprador": "-",
            "iva_de_mercado_vendedor": "-",
            "base_de_prorrateo": "-",
            "monto_minimo": null,
            "monto_maximo": null,
            "is_active": null
        },
        "svs": null
    },
    "plazo": {
        id: "",
        nombre: "Sin Definir",
        hora_inicio: "-",
        hora_final: "-"
    },
    "ofertas": [],
    "usuario": "",
    "estado": "Sin Definir",
    "tasa_comprador": null,
    "borrado": null,
    "ppv": "-",
    "svs": "-",
    "tiempo_minimo": "-",
},
{
    url: "/subasta/id/ofertas",
    "id": 0,
    "oferta_details": [
    {
    "cheque": "-",
    "comitente_comprador": {
    "cuit": "-",
    "nro_comitente": "-",
    "exceptuado": null,
    "id": "",
    "nombre": "Sin Definir",
    "persona_juridica": null,
    "codigo_operador": {
    "id": "",
    "cat_iva_id": "",
    "cat_iva": {
    "id": "",
    "nombre": "Sin Definir"
    },
    "provincia_id": "",
    "provincia": {
    "id": "",
    "provincia_localidades": [
    {
    "id": "",
    "provincia": "",
    "nombre": "Sin Definir"
    }
    ],
    "nombre": "Sin Definir"
    },
    "localidad_id": "",
    "localidad": {
    "id": "",
    "provincia": "Sin Definir",
    "nombre": "Sin Definir"
    },
    "cnv_categ_id": "",
    "cnv_categ": {
    "id": "",
    "nombre": "Sin Definir"
    },
    "tipo_cliente_id": "",
    "tipo_cliente": {
    "id": "",
    "nombre_tipo": "Sin Definir"
    },
    "razon_social": "Sin Definir",
    "cuit": "-",
    "domicilio": "Sin Definir",
    "telefono": "Sin Definir",
    "email_dif_pub": "Sin Definir",
    "email_institucional": "Sin Definir",
    "sitio_web": "Sin Definir",
    "fec_creacion": "-",
    "fec_actualiz": "-",
    "activo": null,
    "cod_caja_cpd": "-",
    "cod_caja_val": "-",
    "membresia": null,
    "codigo_op_old": ""
    },
    "is_active": null
    },
    "comitente_comprador_id": ""
    }
    ],
    "exceptuado": null,
    "origen": "Sin Definir",
    "tasa": "-",
    "hora": "-",
    "is_active": null,
    "cliente": "",
    "usuario": "",
    "subasta": ""
},
{
    url: "/responsables_cv",
    "id": "",
    "nro_secuencial": "-",
    "tipo_de_informacion": "-",
    "tipo_de_novedad": "-",
    "tipo_de_responsable": "Sin Definir",
    "estado_de_responsable": "Sin Definir",
    "denominacion_responsable": "Sin Definir",
    "cuit": "-",
    "codigos": [
        "-"
    ]
},
{
    url: "/garantias_cpd/informes",
    "cotizacion__cod_esp": "-",
    "cotizacion__nro_esp": "-",
    "cantidad_": "-",
    "comitentes": "-",
    "agentes": "-",
    "total": "-"
},
{
  url: "/totales_operados",
  extra: ["fecha"],
  "vencimiento": null,
  "plazo": null,
  "segmento": "Sin Definir",
  "tipo_instrumento": "Sin Definir",
  "tasa_min": "-",
  "tasa_max": "-",
  "tasa_prom": "-",
  "monto_nominal": "-",
  "monto_liquidado": "-",
  "cantidad": "-"
},
{
  url: "/concertaciones",
  extra: ["concertacion"],
  "id": "",
  "subasta": "",
  "segmento": "-",
  "moneda": "-",
  "tipo_operacion": "-",
  "tasa": "-",
  "contraparte": "-",
  "hora_concertacion": "-",
  "concertacion": "-",
  "liquidacion": "-",
  "registro_operacion": "-",
  "id_cheque": "-",
  "banco": "-",
  "nro_cheque": "-",
  "acreditacion": "-",
  "monto": "-",
  "descuento": "-",
  "derecho_mercado": "-",
  "comitente": "-",
  "cuit_comprador": "-",
  "hora_orden": "-",
  "warrant": "-",
  "condicion": "-",
  "codigo_cheque": null,
  "moneda_liquidacion": "-",
  "tipo_de_cambio": "-",
  "sin_recurso": null,
  "nalo": null,
  "vencimiento": "-",
  "nombre_banco": " -",
  "nro_sucursal": "-",
  "epyme": null,
  "primera_negociaion": null,
  "tipo_instrumento": "-",
  "custodio": "Sin Definir",
  "echeqid": "-",
  "plaza_cheque": "-",
  "nro_cta_librador": "-",
  "iva_derecho_mercado": "-",
  "cuit_librador": "-",
  "cuit_pyme": "-",
  "liquidador_compra": "-",
  "baja": null
},
{
  url: "/totales_operados_comitentes",
  extra: ["fecha"],
  "comitente": "-",
  "cuit": "-",
  "segmento": "-",
  "moneda": "-",
  "comprado": "-",
  "vendido": "-",
  "neto": "-"
},
{
  url: "/cheques/informes/librador",
  extra: ["librador"],
  cheques: [
    {
        "cheques_ingresados": "-",
        "negociado": "-",
        "no_negociado": "-"
    }
  ],
  cheques_ingresados: {
    "id": "",
    "cuenta": "-",
    "sucursal": "-",
    "plaza": "-",
    "cuit_librador": "-",
    "monto": "-",
    "endosos": "",
    "comitente_presentante": {
        "cuit": "-",
        "nro_comitente": "-",
        "exceptuado": null,
        "id": "",
        "nombre": "Sin Definir",
        "persona_juridica": null,
        "codigo_operador": {
          "id": "",
          "cat_iva_id": "",
          "cat_iva": {
            "id": "",
            "nombre": "Sin Definir"
          },
          "provincia_id": "",
          "provincia": {
            "id": "",
            "provincia_localidades": [
              {
                "id": "",
                "provincia": "Sin Definir",
                "nombre": "Sin Definir"
              }
            ],
            "nombre": "Sin Definir"
          },
          "localidad_id": "",
          "localidad": {
            "id": "",
            "provincia": "Sin Definir",
            "nombre": "Sin Definir"
          },
          "cnv_categ_id": "",
          "cnv_categ": {
            "id": "",
            "nombre": "Sin Definir"
          },
          "tipo_cliente_id": "",
          "tipo_cliente": {
            "id": "",
            "nombre_tipo": "Sin Definir"
          },
          "razon_social": "Sin Definir",
          "cuit": "-",
          "domicilio": "Sin Definir",
          "telefono": "Sin Definir",
          "email_dif_pub": "Sin Definir",
          "email_institucional": "Sin Definir",
          "sitio_web": "Sin Definir",
          "fec_creacion": "-",
          "fec_actualiz": "-",
          "activo": null,
          "cod_caja_cpd": "",
          "cod_caja_val": "",
          "membresia": null,
          "codigo_op_old": ""
        },
        "is_active": null
      },
    "comitente_tenedor": {
        "cuit": "-",
        "nro_comitente": "-",
        "exceptuado": null,
        "id": "",
        "nombre": "Sin Definir",
        "persona_juridica": null,
        "codigo_operador": {
          "id": "",
          "cat_iva_id": "",
          "cat_iva": {
            "id": "",
            "nombre": "Sin Definir"
          },
          "provincia_id": "",
          "provincia": {
            "id": "",
            "provincia_localidades": [
              {
                "id": "",
                "provincia": "Sin Definir",
                "nombre": "Sin Definir"
              }
            ],
            "nombre": "Sin Definir"
          },
          "localidad_id": "",
          "localidad": {
            "id": "",
            "provincia": "Sin Definir",
            "nombre": "Sin Definir"
          },
          "cnv_categ_id": "",
          "cnv_categ": {
            "id": "",
            "nombre": "Sin Definir"
          },
          "tipo_cliente_id": "",
          "tipo_cliente": {
            "id": "",
            "nombre_tipo": "Sin Definir"
          },
          "razon_social": "Sin Definir",
          "cuit": "-",
          "domicilio": "Sin Definir",
          "telefono": "Sin Definir",
          "email_dif_pub": "Sin Definir",
          "email_institucional": "Sin Definir",
          "sitio_web": "Sin Definir",
          "fec_creacion": "-",
          "fec_actualiz": "-",
          "activo": null,
          "cod_caja_cpd": "",
          "cod_caja_val": "",
          "membresia": null,
          "codigo_op_old": ""
        },
        "is_active": null
      },
    "banco": {
        "id": "",
        "codigo_banco": "Sin Definir",
        "nombre_banco": "Sin Definir",
        "abreviatura_nombre_banco": "Sin Definir",
        "is_active": null
    },
    "segmento": {
        "id": "",
        "tipo": "Sin Definir",
        "denominacion": "Sin Definir"
    },
    "custodio": "Sin Definir",
    "usuario": {
        "id": "",
        "cliente": {
        "id": "",
        "cat_iva_id": "",
        "cat_iva": {
        "id": "",
        "nombre": "Sin Definir"
        },
        "provincia_id": "",
        "provincia": {
        "id": "",
        "provincia_localidades": [
        {
        "id": "",
        "provincia": "Sin Definir",
        "nombre": "Sin Definir"
        }
        ],
        "nombre": "Sin Definir"
        },
        "localidad_id": "",
        "localidad": {
        "id": "",
        "provincia": "",
        "nombre": "Sin Definir"
        },
        "cnv_categ_id": "",
        "cnv_categ": {
        "id": "",
        "nombre": "Sin Definir"
        },
        "tipo_cliente_id": "",
        "tipo_cliente": {
        "id": "",
        "nombre_tipo": "Sin Definir"
        },
        "razon_social": "Sin Definir",
        "cuit": "-",
        "domicilio": "Sin Definir",
        "telefono": "Sin Definir",
        "email_dif_pub": "Sin Definir",
        "email_institucional": "Sin Definir",
        "sitio_web": "Sin Definir",
        "fec_creacion": "-",
        "fec_actualiz": "-",
        "activo": null,
        "cod_caja_cpd": "-",
        "cod_caja_val": "-",
        "membresia": null,
        "codigo_op_old": "-"
        },
        "cliente_id": "",
        "user_type": {
        "id": "",
        "name": "Sin Definir"
        },
        "user_type_id": "",
        "user_params_id": {
        "property1": "Sin Definir",
        "property2": "Sin Definir"
        },
        "user_params": null,
        "last_login": "-",
        "first_name": "Sin Definir",
        "last_name": "Sin Definir",
        "is_staff": null,
        "is_active": null,
        "date_joined": "-",
        "email": "Sin Definir",
        "date_modified": "-",
        "photo": "https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png",
        "admin": null,
        "admin_cupos": null,
        "admin_dma": null,
        "cuit": "-",
        caduca: "-"
    },
    "instrumento": {
        "id": "",
        "tipo": "Sin Definir",
        "descripcion": "Sin Definir",
        "derecho_de_mercado_comprador": "-",
        "derecho_de_mercado_vendedor": "-",
        "iva_de_mercado_comprador": "-",
        "iva_de_mercado_vendedor": "-",
        "base_de_prorrateo": "-",
        "monto_minimo": "-",
        "monto_maximo": null,
        "is_active": null
    },
    "moneda": {
        "id": "",
        "denominacion": "Sin Definir",
        "signo": "-"
    },
    "lote": {
      "lote_id": "",
      "estado": "Sin Definir",
      "subasta": {
          "id": ""
      }
    },
    "remesa": "-",
    "estado": {
        "id": "",
        "estado_nombre": "Sin Definir"
    },
    "certificado_pyme": {
      "id": "",
      "razon_social": "Sin Definir",
      "cuit": "-",
      "fecha_vencimiento": "-",
      "condicion": {
          "id": "",
          "nombre": "Sin Definir"
      },
      "documentacion": null,
      "activo": null
    },
    "fecha_emision": "-",
    "fecha_de_pago": "-",
    "fecha_de_cobro": "-",
    "nro_cheque": "-",
    "clausula_no_a_la_orden": null,
    "endosado_sin_recurso": null,
    "primera_negociacion": null,
    "expone_librador": null,
    "pyme": null,
    "echeq_id": null,
    "codigo": null,
    "svs": null
  },
  detalle: [
    {
        "comitentes_ingreso": "-",
        "comitentes_tenencia": "-",
        "cta_cte": "-",
        "rechazos": "-"
    }
  ]
}
];

