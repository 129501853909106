import React from 'react';
import { Button, Result } from 'antd';
import { useDispatch } from 'react-redux';

const PermisoDenegadoMensaje = ({mensaje}) => {

    const dispatch = useDispatch();

    const onSignOut = e => {
        e.preventDefault();
        dispatch({type: "autentication/desloguear"});
    };

  return <Result
    status="error"
    title="Permiso Denegado"
    subTitle={mensaje}
    extra={[
      <Button onClick={onSignOut} type="primary" key="console">
        Cerrar Sesión
      </Button>,
    ]}
  >
  </Result>
};
export default PermisoDenegadoMensaje;