import React, { useEffect, useState } from "react";
import { Col, Typography } from "antd";
import FeatherIcon from "feather-icons-react";
import HeaderPositioningDiv from "./styles";
import { useDispatch, useSelector } from "react-redux";
const { Text } = Typography;

const UserDataHeader = () => {
  const [userData, setUserData] = useState();
  const [idPers, setIdPers] = useState();
  const dispatch = useDispatch();
  const {idPersonalizado} = useSelector((state) => state.autenticacionReducer);
  const {usuario} = useSelector((state) => state.usuarioReducer);

  useEffect(() => {
    if (usuario && usuario.first_name && usuario.last_name)
      setUserData(usuario);
  }, [usuario]);

  useEffect(() => {
    if (idPersonalizado)
      setIdPers(idPersonalizado);
  }, [idPersonalizado]);

  return (
    <>
      {userData &&
        <Col flex={2} style={{ textAlign: "center" }}>
          <Text> {`${userData.first_name} ${userData.last_name} - ${userData.cliente && userData.cliente.razon_social} (${userData.cliente && userData.cliente.cod_op})`} </Text>
        </Col>}

      {userData && userData.is_staff && idPers &&
        <Col flex={2} className="client-position-header">
          <HeaderPositioningDiv>
            <span style={{fontWeight: "bold"}}>({idPers.cod_op}) - {idPers.nombre}</span>
            <FeatherIcon icon="x-circle" size={24} onClick={() => dispatch({type: "autentication/despersonalizar"})} color="red" id="cancel-personalitation-icon" />
          </HeaderPositioningDiv>
        </Col>
      }
    </>
  )
}

export default UserDataHeader;