const reviso = (key, objeto, mock) => {
    if(typeof objeto === "object") return iterador(objeto, mock, key);
    else return objeto;
}

const iterador = (objeto, mock, keyy = undefined) => {
    for ( let key in objeto ) {
    let keysResponse = objeto[key];
    if(keysResponse === undefined || keysResponse === null || keysResponse === "") {
        if(keyy) {
          // para cuando es un objeto de segundo nivel o superior
          for(let mockKey in mock) {
            if(mockKey === keyy) objeto[key] = mock[mockKey][key];
          }
        } else {
          // para cuando es un objeto de primer nivel
          for(let mockKey in mock) {
            if(mockKey === key) objeto[key] = mock[mockKey];
          }
        }
    } else {
      reviso(key, keysResponse, mock);
    }
  };
    return objeto;
}

const adapter = (arrayResponse, mock) => {
  arrayResponse.results ? arrayResponse.results = arrayResponse.results.map(objeto => iterador(objeto, mock)) : arrayResponse = iterador(arrayResponse, mock);
  return arrayResponse;
};

export default adapter;