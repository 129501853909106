import { configureStore, combineReducers } from "@reduxjs/toolkit";
import listadoSubastaReducer from "./reducers/subasta/listado";
import detalleSubastaReducer from "./reducers/subasta/detalle";
import comitenteSubasta from "./reducers/subasta/comitente";
import autenticacionReducer from "./reducers/autenticacion";
import usuarioReducer from "./reducers/usuario";
import uiReducer from "./reducers/ui";

const rootReducer = combineReducers({
    listadoSubasta: listadoSubastaReducer,
    detalleSubasta: detalleSubastaReducer,
    comitenteSubasta: comitenteSubasta,
    autenticacionReducer,
    usuarioReducer,
    uiReducer
});

const store = configureStore({ reducer: rootReducer });

export default store;


























// import { configureStore, combineReducers } from "@reduxjs/toolkit";
// import listadoSubastaReducer from "./reducers/subasta/listado";
// import detalleSubastaReducer from "./reducers/subasta/detalle";
// import comitenteSubasta from "./reducers/subasta/comitente";
// import autenticacionReducer from "./reducers/autenticacion";
// import usuarioReducer from "./reducers/usuario";
// import uiReducer from "./reducers/ui";
// import { usuarioLocalStorage } from "./reducers/autenticacion";
// import { buscarUsuario } from "./reducers/usuario";
// import { getItem } from "./utility/localStorageControl";

// const rootReducer = combineReducers({
//     listadoSubasta: listadoSubastaReducer,
//     detalleSubasta: detalleSubastaReducer,
//     comitenteSubasta: comitenteSubasta,
//     autenticacionReducer,
//     usuarioReducer,
//     uiReducer
// });

// let store = configureStore({ reducer: rootReducer });

// const loadInitialState = async () => {
//     try {
//       // Realiza la consulta asincrónica
//       const informacionAsincrona = await store.dispatch(usuarioLocalStorage());
//       const usuarioLogueado = await store.dispatch(buscarUsuario());
// console.log("usuarioLogueado", usuarioLogueado);
//       // Crea el estado inicial con la información obtenida
//       const initialState = {
//         listadoSubasta: [],
//         detalleSubasta: {
//             detalleSubasta: {},
//             showDeleteButton: false,
//             deshabilitarInput: false,
//             mostrarCargaOferta: true
//         },
//         comitenteSubasta: {
//             comitentes: [],
//             crearComitente: 0,
//             comitenteNoValido: false,
//             cheques: [],
//             repetirComitente: [],
//             chequesConComitentes: []
//         },
//         autenticacionReducer: {
//             loading: "initial",
//             isLoggedIn: false,
//             idPersonalizado: getItem("pers") || false,
//             errorMsge: "",
//             permisos: {},
//             logueado: "deslogueado",
//             usuarioLS: informacionAsincrona.payload
//         },
//         usuarioReducer: {
//             usuario: usuarioLogueado.payload,
//             imageChange: false,
//             loading: "initial",
//             logueado: false
//         },
//         uiReducer: {
//             darkMode: false,
//             menuStyle: true
//         }
//     };

//     const tipoUsuarioLogueado = usuarioLogueado?.payload?.user_type?.name;
    
//     if(tipoUsuarioLogueado) {
//       switch (tipoUsuarioLogueado) {
//         case "Difusión SGR":
//           initialState.autenticacionReducer.permisos.difusionSgr = true;
//           break;
      
//         case "Operador":
//           initialState.autenticacionReducer.permisos.operador = true;
//           break;
  
//         case "Operador Cta. Excl.":
//           initialState.autenticacionReducer.permisos.carteraLimitada = true;
//           break;
  
//         case "Caja":
//           initialState.autenticacionReducer.permisos.caja = true;
//           break;
  
//         case "Difusión CPD":
//           initialState.autenticacionReducer.permisos.difusionCpd = true;
//           break;
//         // default: initialState
//         //   break;
//       };
  
//       initialState.autenticacionReducer.isLoggedIn = true;
//       initialState.autenticacionReducer.logueado = "logueado";
//       initialState.usuarioReducer.logueado = true;
  
//       // if(getItem("pers")) {
//       //     const { id, nombre } = getItem("pers");
//       //     console.log(id, nombre);
//       //     initialState.autenticacionReducer.idPersonalizado = { id, nombre };
//       // } else {
//       //     initialState.autenticacionReducer.idPersonalizado = false;
//       // }
  
//       store = configureStore({ reducer: rootReducer, preloadedState: initialState, middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: {extraArgument: { dispatch: store.dispatch }}})})}

//     } catch (error) {
//       console.error('Error al cargar la información asincrónica:', error);
//     }
//   };

//   if(getItem("loggedIn")) { loadInitialState() };

//   export default store;
