import axios from 'axios';
import eventBusService, { EXPIRED_SESSION_EVENT } from '../../services/eventBusService';
import { getItem } from '../../utility/localStorageControl';
import adapter from '../../normalizer';
import { mocks } from '../../mocks/clientes';

const loggedInKey = 'loggedIn';
const personalizarKey = 'pers';
const API_URL = process.env.REACT_APP_API_ENDPOINT || "https://test.api.mav-cpd.com.ar/";

const client = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

class DataService {
  async get(path = '', headers) {
    return client({
      method: 'GET',
      url: path,
      headers
    });
  }

  async post(path = '', data = {}) {
    return client({
      method: 'POST',
      url: path,
      data: JSON.stringify(data),
    });
  }

  async patch(path = '', data = {}, headers) {
    return client({
      method: 'PATCH',
      url: path,
      data: JSON.stringify(data),
      headers: {
        body: headers
      }
    });
  }

  async put(path = '', data = {}) {
    return client({
      method: 'PUT',
      url: path,
      data: JSON.stringify(data),
    });
  }

  async delete(path = '') {
    return client({
      method: 'DELETE',
      url: path,
    });
  }
}

client.interceptors.request.use(
  config => {
    const requestConfig = config;
    const { headers } = config;
    const id = getItem(personalizarKey) && getItem(personalizarKey).id;
    //if (id && !config.url.includes("/users/2")) headers['Client-Id'] = id;
    if (id) headers['Client-Id'] = id;
    requestConfig.headers = {
      'Authorization': getItem(loggedInKey) && getItem(loggedInKey).access ? `Bearer ${getItem(loggedInKey).access}` : null,
      ...headers,
    };
    if(headers.export === "xls" || headers.export === "zip") {
      requestConfig.data = null;
      requestConfig.headers["Content-Type"] = "blob"
      //requestConfig.headers = {'Content-Type': 'blob', ...requestConfig.headers}
      requestConfig["responseType"] = 'arraybuffer';
    }
    return requestConfig;
  },
  error => Promise.reject(error)
);


client.interceptors.response.use(
  response => {
    if(response.config.method === "get") {
      const convierto = JSON.stringify(mocks);
      const parseo = JSON.parse(convierto);
      const c1 = response.config.url.replace(/(=)([^&]+)/g, '$1number');
      const rompo = c1.split('?');
      let resultado = rompo[0]; 
      let segmentos;
      if(rompo.length > 1) {
        segmentos = rompo[1].split('&').filter(seg => !seg.includes('limit') && !seg.includes('offset'));
        if (segmentos.length > 0) {
          resultado += '?' + segmentos.join('&');
        }
      }
      resultado = resultado.replace(/\/$/, '');
      // esto es para los endpoint que son: /users/2. Reemplaza todos los numeros por la palabra id, resultando en /users/id
      const c2 = resultado.replace(/\/(\d+)(?=\/|$)/g, '/id');

      const mockActual = parseo.find(mock => mock.url === c2);

      // en este if entra siempre cuando es la primer consulta de la pantalla actual
      if(mockActual) return adapter(response.data, mockActual);
      // en este else entra cuando se usan filtros u ordenamientos
      else {
        const match = response.config.url.match(/\/([^/?]+)/);
        if (match) {
          const capturoPalabra = match[1];
          const mocksMatchean = parseo.filter(mock => mock.url.includes(capturoPalabra)).filter(mock => mock.extra);
          const miMock = mocksMatchean.find(mock => mock.extra.find(extra => response.config.url.includes(extra)));
          return adapter(response.data, miMock);
        }
      }
    } else return response.data;
  },
  error => {
    if(error.response?.data?.code === "user_inactive") return eventBusService.dispatch(EXPIRED_SESSION_EVENT);
    if (error.response && (error.response.status === 401 || error.response.data.code === 'token_not_valid')) {
      if (error.response.data.code === 'token_not_valid') {
        // agregar logica para refrescar token
        eventBusService.dispatch(EXPIRED_SESSION_EVENT);
      } else
        return error.response;
    } else return Promise.reject(error);

  }
);

// const usarHeaderClientId = (route, method) => {
//   return clientIdEnHeader.find(val => route.indexOf(val.route) > -1 && val.methods.indexOf(method.toUpperCase()) > -1);
// }

export { DataService };