
import { DataService } from "../config/dataService";

class ConstantesService extends DataService {

    getCatCNVList = () => this.get('/categorias_cnv/');

    getTiposClientesList = () => this.get('/clientes/tipos/');

    getCatIVAList = () => this.get('/categorias_iva/');
    
    getProvinciasList = () => this.get('/province/');

    getUserTypes = () => this.get('/users/type/');
}

export default ConstantesService;