import {createReducer} from "@reduxjs/toolkit";

const initialState = {
    darkMode: false,
    menuStyle: true
};

// true = aside, false = fixedTop

const uiReducer = createReducer(initialState, (builder) => {
    builder.addCase("uiReducer/cambiarEstiloMenu", (state, action) => {
        state.menuStyle = !state.menuStyle;
    });
});

export default uiReducer;