import React from 'react';
import { Button, Result } from 'antd';

const PermisoDenegado = () => {

  return <Result
    status="error"
    title="Permiso Denegado"
    subTitle="No cuentas con los permisos necesarios para ingresar a esa página. Por favor, retrocede."
    extra={[
      <Button onClick={() => {
          window.history.back();
          //window.location.reload()
      }} type="primary" key="console">
        Volver
      </Button>,
    ]}
  >
  </Result>
};
export default PermisoDenegado;