import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Avatar, Button } from 'antd';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, UserDropDwon } from './styles';
import Message from './message';
import Notification from './notification';
import Settings from './settings';
import { Popover } from '../../components/popup/';
import Heading from '../../components/heading/';
import SubirFotoPerfil from '../../pages/usuarios/subirFotoPerfil';
import ConstantesService from '../../services/constantesService';
import { useDispatch, useSelector } from 'react-redux';

const AuthInfo = () => {
  const [showEditImage, setShowEditImage] = useState(false);
  const [usuarioTipo, setUsuarioTipo] = useState('');
  const dispatch = useDispatch();
  const {usuario} = useSelector((state) => state.usuarioReducer);

  const constantesService = useMemo(() => new ConstantesService(), []);
  const [userTypes, setUserTypes] = useState([]);

  const fetchData = useCallback(async () => {
    try {
        const [tiposUsuario] = await Promise.all([constantesService.getUserTypes()]);

        setUserTypes(tiposUsuario.results);
    } catch (error) {
        console.log(error);
    }
}, [constantesService]);

if (usuario && userTypes.length > 0) setUsuarioTipo(userTypes.find(x => x.id === usuario.user_type)?.name);

  const onSignOut = e => {
    e.preventDefault();
    dispatch({type: "autentication/desloguear"});
    dispatch({type: "detalleSubasta/desloguear"});
  };

  useEffect(() => {
    userTypes.length < 0 && fetchData();
    // eslint-disable-next-line
  }, [fetchData]);

  // useEffect(() => {
  //   if (usuario && userTypes)
  //     setUsuarioTipo(userTypes.find(x => x.id === usuario.user_type)?.name);
  // }, [usuario, userTypes]);

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img src={usuario && usuario.photo ? usuario.photo : "https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png"} alt="foto de perfil" width="45" height="45" />
          <figcaption>
            <Heading as="h5">
              {(usuario && (usuario.first_name || usuario.last_name)) && <span>{usuario.first_name} {usuario.last_name}</span>}
              {(usuario && (!usuario.first_name && !usuario.last_name)) && <span>{usuario.email}</span>}
            </Heading>
            <p>{ usuario && usuario.user_type.name } { usuario && usuario.is_staff && <span>- Staff</span> }</p>
          </figcaption>
        </figure>

        <Link to="/password/change/logged" style={{textAlign: "center"}}><FeatherIcon icon="lock" size={12} /> Cambiar contraseña</Link>

        <Button style={{marginTop: 10, display:"flex", justifyContent:"center", alignItems:"center"}} onClick={() => setShowEditImage(true)}><FeatherIcon icon="image" size={12} style={{marginRight: 5}}/> Cambiar foto</Button>

        <Link className="user-dropdwon__bottomAction" onClick={onSignOut} to="#">
          <FeatherIcon icon="log-out" /> Salir
        </Link>
      </div>
    </UserDropDwon>
  );

  return (
    <>
    <InfoWraper>
      {/* <Message />
      <Notification />
      <Settings /> */}
      {/* <Support /> */}

      {usuario && usuario.user_type && usuario.user_type.id_perfil === 3 && usuario.is_staff && <div>
        <Button type="link"><Link to="/internos/agentes/clientes">Cambio Agente Operador</Link></Button>
      </div>}

      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <Avatar src={usuario && usuario.photo ? usuario.photo : "https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png"} />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
    {showEditImage && <SubirFotoPerfil showEditImage={showEditImage} setShowEditImage={setShowEditImage} />}
    </>
  );
};

export default AuthInfo;