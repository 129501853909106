import React from 'react';
import { ThemeProvider } from 'styled-components';
import { ConfigProvider } from 'antd';
import esES from 'antd/lib/locale/es_ES';
import Routes from './routes/';
import './static/css/style.css';
import config from './config/config';
import { hot } from 'react-hot-loader/root';
import { withRouter } from 'react-router';
import ErrorHandler from './utility/errorBoundary';

const { theme } = config;

function App() {
	return (
		<ErrorHandler>
			<ConfigProvider locale={esES} direction="ltr">
				<ThemeProvider theme={{ ...theme, rtl: false, topMenu: false, darkMode: false }}>
					<Routes />
				</ThemeProvider>
			</ConfigProvider>
		</ErrorHandler>
	);
}

export default hot(withRouter(App));