import styled from "styled-components";

const HeaderPositioningDiv = styled.div`
display: flex;
justify-content: center;
align-items: center;

span {
    padding-right: 10px;
}

#cancel-personalitation-icon:hover {
    cursor: pointer;
}
`

export default HeaderPositioningDiv;