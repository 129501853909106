import {createReducer} from "@reduxjs/toolkit";

const initialState = {
    listadosubastas: []
};

const listadoSubastaReducer = createReducer(initialState, (builder) => {
    builder.addCase("listadoSubasta/getData", (state, action) => {
        state.listadosubastas = action.payload;
    });
});

export default listadoSubastaReducer;