import { rutasBloqueadasPerfilCaja, rutasBloqueadasAdminBackoffice, rutasBloqueadasOperador, rutasBloqueadasDifusionCPD, rutasBloqueadasDifusionSD, rutasBloqueadasConsultaSD, rutasBloqueadasComitenteDMA, rutasBloqueadasConsultaDMA, rutasBloqueadasCtaExcl, rutasBloqueadasDifusionSGR, rutasBloqueadasOpMonitor, rutasBloqueadasWebServices, rutasBloqueadasConsultaCendeu, rutasBloqueadasComitenteDMACupos } from "../../permisos";
import { useSelector } from "react-redux";
import { Route, Redirect } from 'react-router-dom';
import AdminLayout from "../../../layout";
import PermisoDenegado from "../../../components/permisoDenegado";
import PermisoDenegadoMensaje from "../../../components/permisoDenegado/PermisoDenegadoMsje";

export const MavRoute = ({ loading, path, component}) => {

    // este primer if es por si estas en signIn queriendo entrar al sistema sin loguearte. usuarioLS todavia no tiene ni el valor initial asique no existe
    //if(Object.keys(usuarioLS).length === 0) return <Redirect to="/signIn" />;
    if(loading === "initial") return <Redirect to="/signIn" />;
    return <ValidarPermisosAlNavegar path={path}><Route exact path={path} component={AdminLayout(component, path)} /></ValidarPermisosAlNavegar>;
};

const ValidarPermisosAlNavegar = ({ children, path }) => {
    const {usuarioLS} = useSelector((state) => state.autenticacionReducer);
    const {user_type, user_params} = usuarioLS;
    let venta, compra;
    const {id_perfil} = user_type;

    if(user_params) {
        venta = user_params.venta;
        compra = user_params.compra;
    }

    // esta fc es para cuando no hay validacion extra segun permisos y buscar() es para cuando si hay
    const validate = (rutas) => {
        const validoRuta = rutas && rutas.length > 0;
        let validacionFinal = undefined;
        // undefined pq si rutas no tiene nada es pq no tiene acceso al sistema (webService) asique el valor inicial de validacionFinal lo dejo asi pq el length en ese caso va a dar false
        if(validoRuta) {
            validacionFinal = rutas.find(ruta => path.includes(ruta));
        };
        return validacionFinal;
    };

    const buscar = (arr, busqueda) => {
        const rutasVenta = arr.find(({tipo}) => tipo === busqueda).rutas;
        let permiso;
        permiso = rutasVenta.find(ruta => path.includes(ruta));
        console.log(permiso);
        return permiso;
        //if(permiso) return permiso;
        //if(!permiso) return <PermisoDenegado />;
    };

    // permisos según permiso de compra
    // switch (user_params.venta) {
    //     case true:
    //         if(!validate(rutasBloqueadasComitenteDMA)) return <PermisoDenegado />
    //     break;

    //     case false:
    //         if(!validate(rutasBloqueadasComitenteDMA)) return <PermisoDenegado />
    //     break;

    //     default:
    //         break;
    // };

    switch (id_perfil) {
        case 35: // caja
            if(!validate(rutasBloqueadasPerfilCaja)) return <PermisoDenegado />
        break;

        case 2: // backoffice
            if(!validate(rutasBloqueadasAdminBackoffice)) return <PermisoDenegado />
        break;

        case 3: // operador
            if(!validate(rutasBloqueadasOperador)) return <PermisoDenegado />
        break;

        case 4: // cta. excl.
            if(!validate(rutasBloqueadasCtaExcl)) return <PermisoDenegado />
        break;

        case 31: // difusionSGR
            if(!validate(rutasBloqueadasDifusionSGR)) return <PermisoDenegado />
        break;

        case 10: // difusionSD
            if(!validate(rutasBloqueadasDifusionSD)) return <PermisoDenegado />
        break;

        case 12: // consultaSD
            if(!validate(rutasBloqueadasConsultaSD)) return <PermisoDenegado />
        break;

        case 13: // operador monitor
            if(!validate(rutasBloqueadasOpMonitor)) return <PermisoDenegado />
        break;

        case 32: // difusionCPD
            if(!validate(rutasBloqueadasDifusionCPD)) return <PermisoDenegado />
        break;

        case 14: // comitenteDMA
            let resultado;
            if (!venta && !compra) {
                return <PermisoDenegadoMensaje mensaje={"No tienes acceso a ninguna pantalla. Por favor, verifica tus permisos de usuario y vuelve a loguearte."} />
            } else if(venta && !compra) {
                resultado = buscar(rutasBloqueadasComitenteDMACupos, "venta");
            } else if(!venta && compra) {
                resultado = buscar(rutasBloqueadasComitenteDMACupos, "compra");
            } else {
                resultado = buscar(rutasBloqueadasComitenteDMACupos, "completo");
            };
            
            if(!resultado) return <PermisoDenegado />;
        break;

        case 5: // consultaDMA
            if(!validate(rutasBloqueadasConsultaDMA)) return <PermisoDenegado />
        break;

        case 89: // webServices/REST
            if(!validate(rutasBloqueadasWebServices)) return <PermisoDenegado />
        break;

        case 33: // consultaCendeu
            if(!validate(rutasBloqueadasConsultaCendeu)) return <PermisoDenegado />
        break;

        default:
            break;
    };

    // switch (venta) {
    //     case false: // comitenteDMA
    //         if(buscar(rutasBloqueadasComitenteDMACupos, "venta")) return <PermisoDenegado />
    //     break;

    //     default:
    //         break;
    // };

    // switch (compra) {
    //     case false: // comitenteDMA
    //         if(buscar(rutasBloqueadasComitenteDMACupos, "compra")) return <PermisoDenegado />
    //     break;

    //     default:
    //         break;
    // };

    // if(path.includes("baja")) {
    //     if (Object.keys(usuario).length > 0) {
    //         if (usuario.user_type.name !== "Operador") {
    //             return <Redirect to={"/dashboard"} />
    //         }
    //     }
    // }

    return children;
};