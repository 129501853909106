import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Main, ErrorWrapper } from '../pages/styled';
import Heading from '../components/heading/';
import { Button } from '../components/buttons/buttons';

function ErrorFallback({ error, resetErrorBoundary }) {
    const comp = (<Main>
        <ErrorWrapper>
          <img src={require(`../static/img/pages/404.svg`).default} alt="404" />
          <Heading className="error-text" as="h3">
            404
          </Heading>
          <p>Ha ocurrido un error o la página no existe.</p>
            <Button size="default" type="primary" onClick={resetErrorBoundary}>
              Volver
            </Button>
        </ErrorWrapper>
      </Main>);

    return comp;
}

const ErrorHandler = ({ children }) => {
    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
                // reset the state of your app so the error doesn't happen again
            }}
        >
            {children}
        </ErrorBoundary>
    )
}

export default ErrorHandler;