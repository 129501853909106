import {notification} from "antd";

// CONSTANTES
const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

const categoriasCNV = [{ id: 1, nombre: 'ALyCs' }, { id: 2, nombre: 'AN' }];

const emailPattern = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$";
const webPattern = "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?";
// eslint-disable-next-line
const emailRegex =  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
const nombreRegex = /[,%&$#"!/()=?¡¿'0-9]/
const numeroRegex = /[A-Za-z,.]/
const comitenteRegex = /[A-Za-z,.]/
const defaultPageSize = 25;
const errorGenerico = {
    message: 'Error al buscar los datos.',
    description: 'Vuelva a intentar por favor.',
    placement: 'topRight',
    duration: 3,
    style: {
        marginTop: '15%'
    }
};

const errorGlobal = (status, message = 'Error al buscar los datos.', description = 'Vuelva a intentar por favor.', duration = 3) => {
    if(status.response?.status === 500) {
        return (
            notification.error({
                message: "Ha habido un problema",
                description: "Por favor, espere unos minutos",
                placement: 'topRight',
                duration: 10000000000000,
                style: {
                    marginTop: '15%'
                },
                btn: <button style={{"cursor": "pointer"}} onClick={() => window.location.reload(true)}>Recargar página</button>
            })
        );
    } else {
        return (
            notification.error({
                message,
                description,
                placement: 'topRight',
                duration,
                style: {
                    marginTop: '15%'
                }
            })
        );
    }
};

const objNotificacion = (mensaje, descripcion, duration = 3) => ({
    message: mensaje,
    description: descripcion,
    placement: 'topRight',
    duration,
    style: {
        marginTop: '15%'
    }
});

const mensajeErrorGenerico = "Ha ocurrido un error.";
const categoriasIVA = [
    { id: 1, nombre: 'IVA Responsable Inscripto' },
    { id: 2, nombre: 'IVA Responsable no Inscripto' },
    { id: 3, nombre: 'IVA no Responsable' },
    { id: 4, nombre: 'IVA Sujeto Exento' },
    { id: 5, nombre: 'Consumidor Final' },
    { id: 6, nombre: 'Responsable Monotributo' },
    { id: 7, nombre: 'Sujeto no Categorizado' },
    { id: 8, nombre: 'Proveedor del Exterior' },
    { id: 9, nombre: 'Cliente del Exterior' },
    { id: 10, nombre: 'IVA Liberado – Ley Nº 19.640' },
    { id: 11, nombre: 'IVA Responsable Inscripto – Agente de Percepción' },
    { id: 12, nombre: 'Pequeño Contribuyente Eventual' },
    { id: 13, nombre: 'Monotributista Social' },
    { id: 14, nombre: 'Pequeño Contribuyente Eventual Social' }
];
const tiposCliente = [{ id: 1, nombre: 'SocDep' }, { id: 2, nombre: 'SGR' }, { id: 3, nombre: 'SocGer' }, { id: 4, nombre: 'FC' }];

const ruleCampoRequerido = {
    required: true,
    message: '¡Campo requerido!'
};

const provincias = [
    { id: 1, text: 'Buenos Aires' },
    { id: 2, text: 'Catamarca' },
    /*{ id: 3, text: 'Chaco' },
    { id: 4, text: 'Chubut' },
    { id: 5, text: 'Córdoba' },
    { id: 6, text: 'Corrientes' },
    { id: 7, text: 'Entre Ríos' },
    { id: 8, text: 'Formosa' },
    { id: 9, text: 'Jujuy' },
    { id: 10, text: 'La Pampa' },
    { id: 11, text: 'La Rioja' },
    { id: 12, text: 'Mendoza' },
    { id: 13, text: 'Misiones' },
    { id: 14, text: 'Neuquén' },
    { id: 15, text: 'Río Negro' },
    { id: 16, text: 'Salta' },
    { id: 17, text: 'San Juan' },
    { id: 18, text: 'San Luis' },
    { id: 19, text: 'Santa Cruz' },
    { id: 20, text: 'Santa Fe' },
    { id: 21, text: 'Santiago del Estero' },
    { id: 22, text: 'Tierra del Fuego' },
    { id: 23, text: 'Tucumán' }*/
];

const segmentos_cheques = [
    { id: 'garantizado', text: 'Garantizado' },
    { id: 'cadena_valor', text: 'Cadena de valor' },
    { id: 'granos_fijar', text: 'Granos a fijar' },
    { id: 'warrant', text: 'Warrant' },
];

// const clientIdEnHeader = [
//     { route: 'comitentes', methods: ['POST', 'GET', 'PUT'] },
//     { route: 'remesa/?', methods: ['GET'] },
//     { route: 'cheques/?', methods: ['GET'] },
//     { route: 'lotes/?', methods: ['GET'] },
//     { route: 'subasta/?', methods: ['GET'] },
//     { route: 'users/?', methods: ['GET', 'POST'] },
//     { route: 'users/', methods: ['POST'] },
// ];

export {
    segmentos_cheques, categoriasCNV, categoriasIVA, tiposCliente, emailPattern, webPattern, emailRegex,
    months, provincias, ruleCampoRequerido, errorGenerico, defaultPageSize, mensajeErrorGenerico, nombreRegex, comitenteRegex, numeroRegex,
    objNotificacion, errorGlobal
};