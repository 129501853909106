import {createReducer, createAsyncThunk, current, createAction} from "@reduxjs/toolkit";
import { getItem, setItem } from "../../utility/localStorageControl";
import UsuarioService from "../../services/usuarioService";
import { desencriptarObjeto, encriptarObjeto } from "../../utility/functions";

const initialState = {
    usuario: {},
    imageChange: false,
    token: undefined,
    loading: "initial",
    logueado: false
};

const buscarUsuarioLS = () => {
  const usuarioLocalStorage = getItem("usuario");
  initialState.usuario = usuarioLocalStorage;
  return initialState;
};

export const buscarUsuario = createAsyncThunk(
    "usuario/buscar",
    async (values, {rejectWithValue}) => {
        try {
            const service = new UsuarioService();
            const user_id = await getItem("loggedIn").user_id;
            const response = await service.getUsuario(user_id);
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
  });

  // se ejecuta cuando recargo la pagina y ya estoy logueado, para obtener el usuario logueado del localStorage
  buscarUsuarioLS()

  export const setError = createAction("autentication/desloguear");

const usuarioReducer = createReducer(initialState, (builder) => {
    builder.addCase("usuario/getUser", async (state, action) => {
        //const userData = await desencriptarObjeto(getItem("usuario"));
        const userData = getItem("usuario");
        state.usuario = userData;
    });

    builder.addCase("usuario/modificarImageChange", (state, action) => {
        state.imageChange = true;
        state.usuario.photo = action.payload;
        //encriptarObjeto(state.usuario).then(data => setItem("usuario", data));
        setItem("usuario", state.usuario);
    });

    builder.addCase("usuario/modificarNombreUsuario", (state, action) => {
      if(state.usuario.email === action.payload.email) {
        state.usuario.first_name = action.payload.first_name;
        state.usuario.last_name = action.payload.last_name;
        setItem("usuario", state.usuario);
      }
    });

    builder.addCase(buscarUsuario.pending, (state, action) => {
        if (state.loading === 'initial') {
          state.loading = 'pending'
        }
      });

    builder.addCase(buscarUsuario.fulfilled, (state, action) => {
        if (state.loading === 'pending') {
          state.loading = 'initial';
          state.usuario = action.payload;
          state.imageChange = false;
          setItem("usuario", action.payload);
          // encriptarObjeto(action.payload).then(data => setItem("usuario", data)).catch(datos => {
          //   console.log("datos", datos);
          //   console.log(action);
          //   action?.extra?.dispatch(setError)
          // })
        }
      });

    builder.addCase(buscarUsuario.rejected, (state, action) => {
        if (state.loading === 'pending') {
          state.loading = 'initial';
        }
      });
});

export default usuarioReducer;